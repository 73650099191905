import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class OpcionesService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }


  crearOption( name: string, desc: string, group: string, urlo: string ) {

    const params = {
      name: name,
      description: desc,
      group: group,
      url: urlo,
      token: this.token,
      secret_key: environment.SECRET_KEY
    }

    const url = `${ environment.URL_SERVICIOS }/options?`; //  ?option[name]=${ name }&option[description]=${ desc }&option[group]=${ group }&option[url]=${ urlo }&token=1&secret_key=${ environment.SECRET_KEY };

    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getOptions() {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${ environment.URL_SERVICIOS }/options?`;

    return this.http.get( url, { params } ).pipe(
    map( (resp: any) => {return this.crearArregloOptions(resp);
    }));

    }
  crearArregloOptions( optionsObj: any) {

    const options: any[] = [];
    const resul: any[] = [];

    if ( optionsObj === null ) { return []; }
    Object.keys ( optionsObj ).forEach( key => {
      const rol: any = optionsObj[key];
      options.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in options[0]) {
  //  console.log( usuarios[0][prop].attributes );
    resul.push( options[0][prop].attributes );
    }

   // console.log(resul);

    return resul;

}

getOption( id: string ) {

  const url = `${environment.URL_SERVICIOS}/options/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloOption(resp);
    } ));

}

crearArregloOption( optionObj: any) {

  const option: any[] = [];
  const resul: any[] = [];
 // console.log(usuariosObj);
  if ( optionObj === null ) { return []; }
  Object.keys ( optionObj ).forEach( key => {
    const r: any = optionObj[key];
    option.push( r );
  });
  // tslint:disable-next-line: forin
//  console.log( usuarios[0][prop].attributes );
  resul.push( optionObj.data.attributes );

  return resul;

}

actualizaOption(ido: string, nombre: string, desc: string, group: string, urlo: string) {
  const params = {
    name: nombre,
    description: desc,
    group: group,
    url: urlo,
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
const url = `${environment.URL_SERVICIOS}/options/${ido}?`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

borrarOption(id: string) {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }

  const url = `${ environment.URL_SERVICIOS }/options/${ id }?`;

  return this.http.delete( url, {params} ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

}
