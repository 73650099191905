import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})

export class SidebarComponent implements OnInit {
  // valida opciones disponibles ////////////////////////////////////////
  usuarios = false;
  modemp = false;
  opciones = false;
  opcionesxusuario = false;
  roles = false;
  opcionesxrol = false;
  privilegiosxusuario = false;
  listas = false;
  parametrosgenerales = false;
  serviciosexternos = false;
  tiposarchivos = false;
  documento = false;
  condiciones = false;
  periodospago = false;
  calificacioncreditos = false;
  tarifas = false;
  asignaTiposArchivo = false;
  clientesFondeadores = false;
  aprobarCreditos = false;
  dispersion = false;
  reporteConsultasBuro = false;
  proyectos = false;
  flujo = false;
  doctores = false;
  productos = false;
  carros = false;
  //////////////////////////////////////////////////////////////////////
  visibleSidebar2;
  rid: string;
  roloptions: any[] = [];

  configuracion = false;
  catalogos = false;
  documentacion = false;
  reportes = false;
  creditos = false;
  clientesFondeadoresM = false;
  empleadosCadenas = false;
  cotizador = false;
  revisionDocumentos = false;
  revisionCotizaciones = false;
  menu = []
 

  constructor( private usuariosservice: UsuarioService ) { }

  async ngOnInit() {
    this.menu = [
      { titulo: 'Catálogos',
      icono: 'fa fa-book',
      submenu: [] = [] },
      { titulo: 'Configuración',
      icono: 'fa fa-cog',
      submenu: [] = [] },
      { titulo: 'Reportes',
      icono: 'fa fa-file',
      submenu: [] = [] },
      { titulo: 'Cotizaciones',
      icono: 'fa fa-usd',
      submenu: [] = [] },
      { titulo: 'Empleados y cadenas',
      icono: 'fa fa-user',
      submenu: [] = [] },
      
    ]
    if(localStorage.getItem('rid')){
      this.rid = localStorage.getItem('rid');
      this.usuariosservice.getrol(this.rid).subscribe(resp => {
        for (const prop in resp.data.relations.options) {
          this.roloptions.push(resp.data.relations.options[prop].attributes);
        }
        //console.log(this.roloptions);
        let catalogos = this.roloptions.filter((element) =>
          element.group === "CATÁLOGOS"
        );
        let configuracion = this.roloptions.filter((element) =>
          element.group === "CONFIGURACIÓN"
        );
        let reportes = this.roloptions.filter((element) =>
          element.group === "REPORTES"
        );
        let creditos = this.roloptions.filter((element) =>
          element.group === "CREDITOS"
        );
        let eyc = this.roloptions.filter((element) =>
          element.group === "EMPLEADOS Y CADENAS"
        );
          this.menu[0].submenu.push(catalogos);
          this.menu[1].submenu.push(configuracion);
          this.menu[2].submenu.push(reportes);
          this.menu[3].submenu.push(creditos);
          this.menu[4].submenu.push(eyc);
          let indx = [];
          for (const h in this.menu) {
            if (this.menu[h].submenu[0].length === 0) {  
             indx.push(h);
            }
          } 
          for (var i = indx.length -1; i >= 0; i--) {
              this.menu.splice(indx[i],1)
            }
          for (var z in this.menu) {
            this.menu[z].submenu[0].sort((a, b) => a.name.localeCompare(b.name))
          }
      });
    }

  }

  hide(variable){
    switch (variable){
      case 'configuracion':
        this.configuracion = !this.configuracion;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'catalogos':
        this.catalogos = !this.catalogos;
        this.configuracion = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'documentacion':
        this.documentacion = !this.documentacion;
        this.reportes = false;
        this.configuracion = false;
        this.catalogos = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'creditos':
        this.creditos = !this.creditos;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.clientesFondeadoresM = false;
        break;
      case 'clientesFondeadoresM':
        this.clientesFondeadoresM = !this.clientesFondeadoresM;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        break;
      case 'reportes':
        this.clientesFondeadoresM = this.clientesFondeadoresM;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = !this.reportes;
        this.creditos = false;
        break;
      default:
        break;
    }

  }

  setLS(){
    document.getElementById('menuBtn').click();
    this.configuracion = false;
    this.catalogos = false;
    this.documentacion = false;
    this.reportes = false;
    this.creditos = false;
    this.clientesFondeadoresM = false;
  }




}




//TODO LO DE ABAJO ES EL ONINIT ANTES DEL CAMBIO DE MENU//////////
/*
  async ngOnInit() {
    if(localStorage.getItem('rid')){
      this.rid = localStorage.getItem('rid');
      await this.usuariosservice.getrol(this.rid).subscribe( resp => {
        for (const prop in resp.data.relations.options) {
          this.roloptions.push(resp.data.relations.options[prop].attributes);
        }
        
        for ( const i in this.roloptions ) {
          switch(this.roloptions[i].name) {
            case 'Usuarios':
                this.usuarios = true;
                break;
              case 'Opciones':
                this.opciones = true;
                break;
              case 'Opciones por usuario':
                this.opcionesxusuario = true;
                break;
              case 'Roles':
                this.roles = true;
                break;
              case 'Opciones por rol':
                this.opcionesxrol = true;
                break;
              case 'Privilegios por usuario':
                this.privilegiosxusuario = true;
                break; 
              case 'Listas':
              this.listas = true;
              break;
              case 'Parámetros generales':
                this.parametrosgenerales = true;
                break;  
              case 'Servicios externos':
                this.serviciosexternos = true;
                break;
              case 'Tipos de archivos':
                this.tiposarchivos = true;
                break;   
              case 'Documento':
                this.documento = true;
                break;  
              case 'Condiciones':
                this.condiciones = true;
                break;
              case 'Periodos pago':
                this.periodospago = true;
                break;    
              case 'Calificacion creditos':
                this.calificacioncreditos = true;
                break;  
              case 'Tarifas':
                this.tarifas = true;
                break; 
              case 'Asigna tipos de archivo':
                this.asignaTiposArchivo = true;
                break; 
              case 'Clientes y fondeadores':
                this.clientesFondeadores = true;
                break;   
              case 'aprobar creditos':
                this.aprobarCreditos = true;
                break;  
              case 'Dispersion':
                this.dispersion = true;
                break;    
              case 'Reporte Consultas Buro':
                this.reporteConsultasBuro = true;
                break;  
              case 'Proyectos':
                this.proyectos = true;
                break;       
              case 'Empleados Cadenas':
                this.empleadosCadenas = true;
                break;       
              case 'Flujo':
                this.flujo = true;
                break;
              case 'Modificacion empleados':
                this.modemp = true;
                break;
              case 'Doctores':
                this.doctores = true;
                break;
              case 'Productos':
                this.productos = true;
                break;
              case 'Carros':
                this.carros = true;
                break;
              case 'Cotizador':
                this.cotizador = true;
                break;
              case 'Revisar documentos':
                this.revisionDocumentos = true;
                break;
              case 'Revision cotizaciones':
                this.revisionCotizaciones = true;
                break;
          } 
        }
      } );
    }

  }

  hide(variable){
    switch (variable){
      case 'configuracion':
        this.configuracion = !this.configuracion;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'catalogos':
        this.catalogos = !this.catalogos;
        this.configuracion = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'documentacion':
        this.documentacion = !this.documentacion;
        this.reportes = false;
        this.configuracion = false;
        this.catalogos = false;
        this.creditos = false;
        this.clientesFondeadoresM = false;
        break;
      case 'creditos':
        this.creditos = !this.creditos;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.clientesFondeadoresM = false;
        break;
      case 'clientesFondeadoresM':
        this.clientesFondeadoresM = !this.clientesFondeadoresM;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = false;
        this.creditos = false;
        break;
      case 'reportes':
        this.clientesFondeadoresM = this.clientesFondeadoresM;
        this.configuracion = false;
        this.catalogos = false;
        this.documentacion = false;
        this.reportes = !this.reportes;
        this.creditos = false;
        break;
      default:
        break;
    }

  }

  setLS(){
    document.getElementById('menuBtn').click();
    this.configuracion = false;
    this.catalogos = false;
    this.documentacion = false;
    this.reportes = false;
    this.creditos = false;
    this.clientesFondeadoresM = false;
  }

}
*/
