import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// import * as swal from 'sweetalert';
import { AwsService, RolesService, UsuarioService } from '../../../services/service.index';
import { Usuario, Usuario2 } from '../../../models/usuario.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';
import { ListasService } from '../../../services/listas/listas.service';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';

@Component({
  selector: 'app-crear-doctor',
  templateUrl: './crear-doctor.component.html',
  styleUrls: ['./doctores.component.css']
})
export class CrearDoctorComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  forma: FormGroup;
  genero: any[];
  estatus = [];
  roles: any[];
  firmantes: any[];
  empresa = false;
  cliente = false;
  companies = [];
  doctor = new FormGroup({
    rfc: new FormControl( null),
    cedula: new FormControl( null),
    speciality: new FormControl( null),
    seniority: new FormControl( null),
    own_consultory: new FormControl( null),
    fee: new FormControl( null),
    average_fee: new FormControl( null),
    surgery_income: new FormControl( null),
    monthly_income: new FormControl( null),
    month_patients: new FormControl( null),
    extra_incomes: new FormControl( null),
    detail_extra_incomes: new FormControl( null),
    payment_rent: new FormControl( null),
    name: new FormControl( null),
    con_type: new FormControl( null),
  });
  
  auth = new FormGroup({
    name: new FormControl( null),
    email: new FormControl( null),
    password: new FormControl( null),
    user_type: new FormControl( 'DR', []),
    user_vendor_id: new FormControl( localStorage.getItem('uid'), []),
    
  })

  doctor_type = '';

  constructor(
    public _usuarioService: UsuarioService,
    public router: Router,
    private listasService: ListasService,
    private _roles: RolesService,
    private awsService: AwsService,
    private catalogosService: CatalogosService
  ) { }

  ngOnInit() {

  }

  formatCurrency(numero){
    var respuesta = numero.replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    return respuesta;
  }

  setCurrency(numero, opcion){
    var input = (document.getElementById(opcion) as HTMLInputElement);
    var cantidad = this.formatCurrency(numero)
    input.value = cantidad;
    this.doctor.value[opcion] = cantidad.replace(',', '');
  }

  registrarUsuario() {
    swal2.showLoading();

    if(this.doctor_type == ''){
      swal2.fire('Atención', 'Debe seleccionar el tipo de persona', 'info');
      return;
    }
    
    var keys = [
      'fee',
      'average_fee',
      'surgery_income',
      'monthly_income',
      'extra_incomes',
    ]
    keys.forEach(k => {
      try{
        this.doctor.value[k] = this.doctor.value[k].replace(',', '');
      }
      catch{

      }
    })
    if ( this.doctor.invalid ) {
      swal2.close();
      swal2.fire('Atención', 'Faltan datos', 'info');
      return;
    }

    this.doctor.value.name = this.auth.value.name;

    const params = {
      // doctor: this.doctor.value,
      auth: this.auth.value,
      doctor_type: this.doctor_type
    }

    console.log(params);

    this.catalogosService.crearDoctor(params).subscribe( resp => {
          swal2.close();
          console.log(resp);
          swal2.fire({
            title: 'El usuario',
            text: 'fue creado con éxito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.cerraroregusu();
              // this.router.navigate(['/panelcontrol/verDoctores']);
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          try{
            var texto = '';
            Object.keys(err.error.error).forEach(k => {
              texto = texto + k + ': ' + err.error.error[k] + '<br>';
            })
            swal2.fire('Atención', texto, 'info');
          }catch{
            swal2.fire('Atención', 'Ocurrió un error', 'info');
          }
         } );
  }

  cerraroregusu() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }



}


