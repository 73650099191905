import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-verusuarios',
  templateUrl: './verusuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class VerusuariosComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  edusuario = false;
  agregarusuario = false;
  items = [];
  cols = [

    { field: 'id', header: 'ID' },
    { field: 'name', header: 'Nombre' },
    { field: 'email', header: 'Correo' },
    { field: 'job', header: 'Puesto' },
    { field: 'gender', header: 'Genero' },
    { field: 'status', header: 'Estatus' },
    { field: 'herramientas', header: 'Herramientas' }
];
  currentPage = 1;
  itemsPerPage = 10;
  searchTerm = '';
  filaSeleccionada: number;
  showac = false;
  actualizausuarioEmitter = new EventEmitter<Object>();

  constructor( private usuariosservice: UsuarioService ) { }

  ngOnInit(): void {
    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this.edusuario = true;
    this.agregarusuario = false;
    this.usuariosservice.getUsuarios().subscribe(resp => {
      this.items = resp; 
      swal2.close();
    }, (err) => {
      swal2.fire({title: 'Ocurrio un error al cargar la informacion', allowOutsideClick: false })
    });


  }

  ngOnDestroy(): void { 
    this.actualizausuarioEmitter.unsubscribe();
  } 

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get filteredData(): any[] {
    return this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }

  resetIndex() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  desplegarEditarUsuario() {
    this.edusuario = !this.edusuario;
    this.agregarusuario = false;
  }
  
  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  editarUsuario(contid: any, nomcont: any) {
    this.showac = true;
    this.edusuario = false;
    this.agregarusuario = false;
    setTimeout(() => {
      this.actualizausuarioEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  agregar() {
    this.agregarusuario = true;
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  cierraregusr(event) {
    this.showac = false;
    this.edusuario = true;
    this.agregarusuario = false;
    this.ngOnInit();
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

}
