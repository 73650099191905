import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {
  usuarios: any[] = [];
  cols: any[];
  nombre = '';
  constructor( private usuariosservice: UsuarioService ) {
  }

  ngOnInit(): void {
    
    this.usuariosservice.getUsuarios().subscribe( resp => { this.usuarios = resp;
    for (const prop in this.usuarios) {
      if (this.usuarios[prop].role_id !== null) {
        this.usuariosservice.getrol(this.usuarios[prop].role_id).subscribe(r => {
          this.usuarios[prop].nombrerol = r.data.attributes.name;
          })
      } else {
        this.usuarios[prop].nombrerol = 'Sin rol asignado';
      }
      
    }
    } );
    this.cols = [

      { field: 'name', header: 'Nombre' },
      { field: 'email', header: 'Correo' },
      { field: 'job', header: 'Puesto' },
      { field: 'gender', header: 'Genero' },
      { field: 'status', header: 'Estatus' },
      { field: 'nombrerol', header: 'Rol' },
      { field: 'herramientas', header: 'Herramientas' }
  ];

  }

  borrarUsuario(id){
    this.usuariosservice.borrarUsuario(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Usuario eliminado correctamente', 'success');
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

   
  

}
