import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-opcionesusuarios',
  templateUrl: './opcionesusuarios.component.html',
  styleUrls: ['./opcionesusuarios.component.css']
})
export class OpcionesusuariosComponent implements OnInit {

  constructor( public _usuarioservice: UsuarioService,
    public http: HttpClient) { }

usuarios: any[] = [];
cols: any[];
router: Router;

ngOnInit() {
swal2.showLoading();
this._usuarioservice.getUsuarios().subscribe(resp => {this.usuarios = resp; swal2.close(); },
err => {
  console.clear();
  console.log("ocurrio un error");
  swal2.close();
  // console.log(err); 
} );

this.cols = [

{ field: 'id', header: 'ID' },
{ field: 'name', header: 'Nombre' },
{ field: 'email', header: 'Correo' },
{ field: 'privilegios', header: 'Opciones' }

];

}

}
