import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal2 from 'sweetalert2'; 
import { HttpClient } from '@angular/common/http';
import { UsuarioService } from 'src/app/services/service.index';
import { CreditosService } from '../../services/creditos/creditos.service';
import { AwsService } from '../../services/awsser/aws.service';
import { ListasService } from '../../services/listas/listas.service';
import { ReportesService } from '../../services/reportes/reportes.service';
import {DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
declare var $;
import * as fs from 'file-saver';

@Component({
  selector: 'app-aprobar-credito',
  templateUrl: './aprobar-credito.component.html',
  styleUrls: ['./aprobar-credito.component.scss']
})
export class AprobarCreditoComponent implements OnInit {
  uploadForm: FormGroup;
  span_no_documents_found: any;
  fileName = 'Layout.xlsx';
  url: any;
  file: any;
  datosdetalles: any[] = [];
  selectedSol: any[] = [];
  cols: any[] = [];
  solicitudes: any[] = [];
  estatussolicitudes: any[] = [];
  usuarios: any[] = [];
  idu: string;
  documentonulo: boolean;
  uploadURL: Observable<string>;
  uploadProgress: Observable<number>;
  showModal: boolean;
  showModalt: boolean;
  showmodalds: boolean;
  idsolicitud: string;
  foliosolicitud: string;
  carpeta: string;
  nombrearchivosubido: string;
  token: string;
  tokenExpiry: any[] = [];
  credit_id: any[] = [];
  resumen_creditos = [];
  creditos_buro = [];
  payments = [];
  calificaciones = [];
  documents = [];
  requests = [];
  terms = [];
  paymentPeriods = [];
  intereses = 16;
  colDetallesCustomer = [];
  idRechazo = '';
  ngSelect = '';
  urlburo = '';

  creditos = [];
  rates = [];
  ivaRates = [];
  anuality_date = [];
  regimen_fiscal = [];
  regimen_fiscal_contribuyente = [];
  client_status = [];
  filtro = 'SO';
  credit_status = [];
  datosEmpleado = {};
  buro: string;
  analysis: string;
  buroStatus = false;
  analysisStatus = false;
  stepBuro = false;
  rfcBC = '';
  scoreBC = '';
  fechaReporte = '';
  saldosVencidos = '';
  accured_liabilities = '';
  anual_rate = '';
  balance_due = '';
  car_credit = '';
  cash_flow = 'Dato no capturado';
  created_at = '';
  analisis_credit_status = '';
  credit_type = '';
  customer_credit_id = '';
  customer_number = '';
  debt = '';
  debtnum = 0;
  debt_horizon = 'Dato no capturado'; 
  debt_rate = 'Dato no capturado';
  departamental_credit = '';
  discounts = 'Dato no capturado';
  id = '';
  last_key = '';
  lowest_key = '';
  mop_key = '';
  mortagage_loan = 'Dato no capturado';
  net_flow = 'Dato no capturado';
  net_flownum = 0;
  other_credits = '';
  overall_rate = '';
  payment_capacity = '';
  previus_credit = '';
  report_date = '';
  total_amount = '';
  total_cost = '';
  total_debt = '';
  ingresos_netos: any;
  ingresos_netosnum = 0;
  egresos_netos = '';
  egresos_netosnum = 0;
  gastos_familiares:any;
  gastos_familiaresnum = 0;
  renta_casa = '';
  creditos_CP = '';
  creditos_LP = '';
  periodo_pago = '';
  renta_casanum = 0;
  creditos_CPnum = 0;
  creditos_LPnum = 0;
  pdf:any;
  creditoSeleccionadoTabla = [];
  maritalStatus = [];
  lists = [];
  movil = false;
  ine = '';

  constructor(private _formBuilder: FormBuilder,
              public router: Router,
              private route: ActivatedRoute,
              private usuarioservice: UsuarioService,
              private creditosService: CreditosService,
              private formBuilder: FormBuilder, 
              private httpClient: HttpClient, 
              private listasService: ListasService,
              private awsService: AwsService ,
              private reportesService: ReportesService,
              private sanitizer: DomSanitizer 
              ) {}
              
  hide() {
    this.showModal = false;
    this.idsolicitud = '';
    this.foliosolicitud = '';
    this.ngOnInit();
  }

  ngOnInit() {
    Swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    Swal2.showLoading();

    if(this.esNavegadorMovil() || this.resolucionMovil()){
      console.log('es movil');
      this.movil = true;
    }else{
      console.log('no es movil');
      this.movil = false;
    }

    this.token = this.route.snapshot.paramMap.get('token');
    this.usuarioservice.getComiteeTokenStatus(this.token).subscribe( resp => {
      if ( resp.status !== true ) {
        Swal2.fire(
        'El crédito ya fué actualizado',
        resp.message,
        'error'
     );
     this.router.navigate(['/login']);
    } else {
      Swal2.fire(
        'Favor de aprobar o rechazar el crédito',
        '',
        'info'
     );
     this.traeCositas();
      }

      this.credit_id = resp.credit_id;
      // console.log(this.credit_id ) 
      });

    Swal2.close();

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });

    const estatus: any = document.getElementById('estatus');

    this.selectedSol = [];

    this.idu = localStorage.getItem('id'); 

    this.listasService.getLists2().subscribe(lists => {
      lists['list'].forEach(list => {
        this.lists.push(list);
      });
    this.maritalStatus = this.lists.filter(p => p.domain === 'PERSON_MARTIAL_STATUS');
    this.regimen_fiscal = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME');
    })


    
  }

  esNavegadorMovil(){
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|OperaMini|Mobile|mobile|CriOS/i
        .test(navigator.userAgent);
  }

  resolucionMovil(){
    return (window.screen.height <= 800 || window.screen.width <= 800);
  }

  traeCositas(){

    this.creditosService.ext_rates2().subscribe(resp => {
      resp['data'].forEach(rate => {
        if(rate.rate_type === 'TA'){
          this.rates.push(rate);
        }else if(rate.rate_type === 'IVA'){
          this.ivaRates.push(rate);
        }
      }
      ); 
      // console.log("this.rates")
      // console.log(this.rates)
    })

    this.creditosService.getCondiciones2()
      .subscribe(resp => {
        this.terms = resp;
        // console.log("this.terms")
        // console.log(this.terms)
        // this.terms.push({id:'0', key:'Pago Fijo'})
        this.creditosService.getperiodosPagos2()
          .subscribe(resp => { 
            // console.log("RESPUESTA PERIODOS: ")
            // console.log(resp)
            this.paymentPeriods = resp['data'];
            // this.paymentPeriods.push({id:'0', key:'Pago Fijo'});
            this.creditosService.getCredit2(this.credit_id).subscribe(resp => {
              this.creditos = [];
                // if(resp['data']['status'] === 'PA'){
                  // console.log("ENTRA AL IF")
                  let credit = resp['data'];
                  // console.log(credit);
                  this.creditosService.traer_customer2(credit.id).subscribe(customer => {
                    credit.nombre_empleado = customer[0].nombre_cliente;
                    // console.log(credit.payment_period_id)
                    credit.customer = customer[0];
                    // console.log("paymentPeriods: " + this.paymentPeriods)
                    // console.log( this.paymentPeriods)
                    // console.log("credit.payment_period_id: "+ credit.payment_period_id)
                    let pp = this.paymentPeriods.find(p => p.id === credit.payment_period_id); 
                    credit.payment_period = pp['key'];
                    let term = this.terms.find(t => t.id === credit.term_id);
                    credit.term = term.key;
                    this.creditos.push(credit);
                    this.detalleCliente(credit, false);
                  })
                // }
                //  else { console.log("NO ENTRA AL IF")}
              // }
              // )
            })
          })
        })

  this.cols = [

    { field: 'credit_folio', header: 'Folio', moneda: false },
    { field: 'created_at', header: 'Fecha solicitud', moneda: false },
    { field: 'nombre_empleado', header: 'Nombre Empleado', moneda: false },
    { field: 'business_name', header: 'Compañía', moneda: false },
    { field: 'interests', header: 'Intereses', moneda: true },
    { field: 'commission1', header: 'Comisión', moneda: true },
    { field: 'insurance1', header: 'Seguro', moneda: true },
    // { field: 'rate', header: 'Tasa', moneda: false },
    { field: 'total_debt', header: 'Adeudo Total', moneda: true },
    { field: 'total_requested', header: 'Pedido Total', moneda: true },
    // { field: 'anualidad', header: 'Anualidad', moneda: true },
    // { field: 'anuality_date', header: 'Fecha Anualidad', moneda: false },
    { field: 'fixed_payment', header: 'Pago Fijo', moneda: true },
    { field: 'term', header: 'Plazo', moneda: false },
    { field: 'payment_period', header: 'Periodicidad', moneda: false },
  ];
  }

  async detalleCliente(rowData, show) {
    this.showmodalds = show;
    this.datosEmpleado = {};
    this.egresos_netos = rowData.customer.egresos_netos;
    if (!rowData.customer.gastos_familiares){
      this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat('2500').toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.gastos_familiaresnum = parseFloat('2500');
    } else if(rowData.customer.gastos_familiares == 0 ) {
      this.gastos_familiares = parseFloat(this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}))) + 2500;
      this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares) + 2500;
    } else{
    this.gastos_familiares = this.formatNumber(this.esNulo(parseFloat(rowData.customer.gastos_familiares).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
    this.gastos_familiaresnum = parseFloat(rowData.customer.gastos_familiares)
  }

  this.renta_casa = this.formatNumber(this.esNulo(parseFloat(rowData.customer.renta).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
  this.creditos_CP = this.formatNumber(this.esNulo(parseFloat(rowData.customer.creditos_cp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
  this.creditos_LP = this.formatNumber(this.esNulo(parseFloat(rowData.customer.creditos_lp).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
  this.renta_casanum = parseFloat(rowData.customer.renta)
  this.creditos_CPnum = parseFloat(rowData.customer.creditos_cp)
  this.creditos_LPnum = parseFloat(rowData.customer.creditos_lp)

    this.periodo_pago = this.formatNumber(this.esNulo(parseFloat(rowData.customer.periodo_pago).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));

    this.creditosService.tiene_analisis2(rowData.customer).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      if (this.analysisStatus){
        this.analysis = analysis.credit_analysis;
        // console.log("analysis")
        // console.log(this.analysis)
        this.accured_liabilities = this.analysis[0]['accured_liabilities']
        this.debt_horizon = this.analysis[0]['debt_horizon']
        this.anual_rate = this.analysis[0]['anual_rate'];
        this.balance_due = this.analysis[0]['balance_due'];
        this.car_credit = this.analysis[0]['car_credit'];
        this.credit_status = this.analysis[0]['credit_status'];
        this.cash_flow = this.analysis[0]['cash_flow'];
        this.created_at = this.analysis[0]['created_at'];
        this.analisis_credit_status = this.analysis[0]['credit_status'];
        this.credit_type = this.analysis[0]['credit_type'];
        this.customer_credit_id = this.analysis[0]['customer_credit_id'];
        this.customer_number = this.analysis[0]['customer_number'];
        this.debt = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['debt']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.debtnum = parseFloat(this.analysis[0]['debt'])
        this.debt_rate = this.analysis[0]['debt_rate'];
        this.departamental_credit = this.analysis[0]['departamental_credit'];
        this.discounts = this.analysis[0]['discounts'];
        this.id = this.analysis[0]['id'];
        this.last_key = this.analysis[0]['last_key'];
        this.lowest_key = this.analysis[0]['lowest_key'];
        this.mop_key = this.analysis[0]['mop_key'];
        this.mortagage_loan = this.analysis[0]['mortagage_loan'];
        this.net_flow = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['net_flow']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.net_flownum = parseFloat(this.analysis[0]['net_flow'])
        this.other_credits = this.analysis[0]['other_credits'];
        this.overall_rate = this.analysis[0]['overall_rate'];
        this.payment_capacity = this.analysis[0]['payment_capacity'];
        this.previus_credit = this.analysis[0]['previus_credit'];
        this.report_date = this.analysis[0]['report_date'];
        this.total_amount = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['total_amount']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.total_cost = this.analysis[0]['total_cost'];
        this.total_debt = this.analysis[0]['total_debt'];
        this.egresos_netos = this.formatNumber(this.esNulo(parseFloat(this.analysis[0]['monthly_expenses']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
        this.egresos_netosnum = parseFloat(this.analysis[0]['monthly_expenses'])
      }else {
        console.log("NO TIENE ANALISIS")
      }

    })

    this.creditosService.tiene_buro2(rowData.customer).subscribe(buro => {
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        // console.log("BURO")
        // console.log(this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Nombre']['RFC'])
        this.fechaReporte = this.buro[0]['bureau_report']['results'][0]['updatedAt'];
        this.pdf = this.buro[0]['extra1']
        this.urlburo = `https://docs.google.com/viewer?url=${this.pdf}&embedded=true`
        this.rfcBC = this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Nombre']['RFC'];
        this.saldosVencidos = this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos']
        // let calificacionBC = this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']

        // console.log("this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta']")
        // console.log(this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta'])
        this.resumen_creditos = this.buro[0]['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['Cuentas']['Cuenta']
        this.creditos_buro =this.get_ur_credits(this.resumen_creditos)

      }else {
        // stepBuro
        console.log("NO TIENE BURO")
        // document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");

      }

    });

    this.reportesService.getRegistrationInfo2(rowData).subscribe(info => {
      this.datosEmpleado = info[0];
      this.datosEmpleado['dependientes'] = parseInt(this.datosEmpleado['senior_dependents']) + parseInt(this.datosEmpleado['minior_dependents'])
      this.datosEmpleado['martial_status_value'] = this.maritalStatus.find(m => m.key == this.datosEmpleado['martial_status']).value;
      // this.datosEmpleado['fiscal_regime_value'] = this.regimen_fiscal.find(r => r.key == this.datosEmpleado['fiscal_regime']).value;
      this.datosEmpleado['business_name'] = rowData.customer.business_name;
      if(this.datosEmpleado['salary_period'] == 'Quincenal'){
        this.datosEmpleado['month_salary'] = ( parseFloat(this.datosEmpleado['salary']) + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers']))) * 2;
      }else if(this.datosEmpleado['salary_period'] == 'Semanal'){
        this.datosEmpleado['month_salary'] = (parseFloat(this.datosEmpleado['salary'])  + parseFloat(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseFloat(this.esNulo(this.datosEmpleado['food_vouchers']))) * (4.33);
      }
      this.ingresos_netos = this.formatNumber(this.esNulo(parseFloat(this.datosEmpleado['month_salary']).toFixed(2)).toLocaleString('en', {minimumFractionDigits: 2}));
      this.ingresos_netosnum = parseFloat(this.datosEmpleado['month_salary'])
    // this.ingresos_netos = ( parseInt(this.esNulo(this.datosEmpleado['month_salary'])) + parseInt(this.esNulo(this.datosEmpleado['ontime_bonus'])) +  parseInt(this.esNulo(this.datosEmpleado['assist_bonus'])) +  parseInt(this.esNulo(this.datosEmpleado['food_vouchers'])) );
    
    this.awsService.getDocuments2(rowData.customer.id_contribuyente).subscribe(docs => {
      this.documents = [];
      docs['data'].forEach(doc => {
        if(doc.name == "Comprobantes de ingresos del empleado"){
          var arrayUrl = doc.url.split('*');
          arrayUrl.forEach(url => {
            if(url != ''){
              let params = {
                contributor_id: doc.contributor_id,
                created_at: doc.created_at,
                extra1: null,
                extra2: null,
                extra3: null,
                file_type_document_id: doc.file_type_document_id,
                id: doc.id,
                name: doc.name,
                notes: null,
                status: doc.status,
                updated_at: doc.updated_at,
                url: url
              }
              let variable = doc;
              variable.url = url;
              this.documents.push(params);
            }
          });
        }else{
          if(doc.name == 'Identificacion oficial INE'){
            this.ine = doc.url;
          }
          this.documents.push(doc);
        }
        /* if(doc.name != "Comprobantes de ingresos del empleado"){
          if(doc.name == 'Identificacion oficial INE'){
            console.log(doc)
            this.ine = doc.url;
          }
          this.documents.push(doc);
        }else{
          var arrayUrl = doc.url.split('*');
          arrayUrl.forEach(url => {
            if(url != ''){
              doc.url = url;
              this.documents.push(doc);
            }
          });
        } */
      });
      if(this.buroStatus){
        if(this.buro[0]['extra1'] != null){
          this.documents.push({'url': this.buro[0]['extra1'], 'name': 'Reporte Buró de Crédito'});
        }
      }
        if(this.datosEmpleado['extra1'] !== null){
          this.documents.push({'url': this.datosEmpleado['extra1'], 'name': 'Expediente'});
        }        
    })
    });

  }

  toFloat(value: string): number {
    value = value.replace(',', '');
    return parseFloat(value);
 }

  onStepBuro(){
    this.stepBuro = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  // METODO PARA MANDAR A GUARDAR EL STATUS SI EL USUARIO RECHAZA EL CREDITO
  rechazar(){
    // this.showModal = true;
    // this.show();
    let comment = (document.getElementById('notaRechazo') as HTMLInputElement).value
    this.creditosService.updateFirmaCredit(this.token, 'RE', comment).subscribe(resp => {
      Swal2.fire({
        title: 'Exito', 
        text: 'Crédito rechazado', 
        icon: 'success',
        showConfirmButton: true,
        allowOutsideClick: false
      }).then(res => {
        if(res.value){
          document.getElementById('btnAceptar').setAttribute("disabled", "disabled");
          document.getElementById('btnRechazar').setAttribute("disabled", "disabled");
          // this.ngOnInit();
          this.router.navigate(['/login']);
          // this.router.navigate(['/home']);
        }
      });
    }, err => {
      console.log(err);
      Swal2.fire('Error', 'Algo salió mal', 'info');
    })
  }
  // GUARDA EL STATUS EN TABLA CUSTOMER_CREDITS_SIGNATORY COMO AC PARA EL CREDITO Y USUARIO EN CUESTION.
  firmaAceptado(){
    this.creditosService.updateFirmaCredit(this.token, 'AP').subscribe(resp => {
      Swal2.fire({
              title: 'Operación correcta',  
              text: 'Propuesta Aceptada', 
              icon: 'success',
              allowOutsideClick: false,
              showConfirmButton: true
            }).then(resp => {
              if(resp.value){
                document.getElementById('btnAceptar').setAttribute("disabled", "disabled");
                document.getElementById('btnRechazar').setAttribute("disabled", "disabled");
                // document.getElementById('btnAceptar').setAttribute("background-color:#0a0a23;");
                document.getElementById('btnAceptar').style.backgroundColor ="green";
                // document.getElementById('btnAceptar').disabled = true;
                // document.getElementsBySelector("#btnAceptar")[0].setAttribute("disabled","disabled");
                                // document.getElementById(id).style.border = "dashed 2px blue";
                // this.ngOnInit();
                this.router.navigate(['/login']);
                // this.router.navigate(['/home']);
              }
            });
    }, err => {
      console.log('error', err);
      Swal2.fire('', 'Hubo un problema', 'info');
    })
  }

  //------------------------------------------------------------

  get_ur_credits(credits){ 
    var ur_credits = {mop: 'UR', cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0,
                saldo_vencido: 0, pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0,
                saldo_cerradas: 0, monto_cerradas: 0}

    var credits_01 = {mop: '01',cuentas_abiertas: 0, limite_abiertas: 0, maximo_abiertas: 0, saldo_actual: 0, saldo_vencido: 0,
    pago_realizar: 0, cuentas_cerradas: 0, limite_cerradas: 0, maximo_cerradas: 0, saldo_cerradas: 0,
    monto_cerradas: 0}


    var all_credits = []


    credits.forEach(credit =>{
      if(credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_cerradas'] += 1
        ur_credits['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        ur_credits['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_cerradas'] += 1
        credits_01['limite_cerradas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_cerradas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_cerradas'] += parseInt(credit['SaldoActual'])
        credits_01['monto_cerradas'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === 'UR'){
        ur_credits['cuentas_abiertas'] += 1
        ur_credits['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        ur_credits['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        ur_credits['saldo_actual'] += parseInt(credit['SaldoActual'])
        ur_credits['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        ur_credits['pago_realizar'] += parseInt(credit['MontoPagar'])
      }else if (!credit['FechaCierreCuenta'] && credit['FormaPagoActual'] === '01'){
        credits_01['cuentas_abiertas'] += 1
        credits_01['limite_abiertas'] += parseInt(credit['LimiteCredito'] ? credit['LimiteCredito'] : 0)
        credits_01['maximo_abiertas'] += parseInt(credit['CreditoMaximo'])
        credits_01['saldo_actual'] += parseInt(credit['SaldoActual'])
        credits_01['saldo_vencido'] += parseInt(credit['SaldoVencido'])
        credits_01['pago_realizar'] += parseInt(credit['MontoPagar'])
      }
    });

    all_credits.push(ur_credits)
    all_credits.push(credits_01)

    return all_credits
  }

  esNulo (dato){
    if(dato == null){
      return 0;
    }else{
      return dato;
    }
  }

  show(datarow) {
    this.showModal = true; // Show-Hide Modal Check
    // console.log(datarow);
    this.idsolicitud = datarow.id;
    this.foliosolicitud = datarow.folio;
    // return '2';
  }

  hideds() {
    this.showmodalds = false;
  }  

  showModalTabla(data) {
    // console.log("data")
    // console.log(data)
    var id = data.id;
    this.creditoSeleccionadoTabla = data;
    this.payments = [];
    this.creditosService.customerCreditPayments2(data.id).subscribe(pays => {
      pays['data'].forEach(p => {
/*         this.payments.push(p['attributes']); */
        const payment = p;/* borrar */
        if(payment.commission == null ){
          payment.commission = 0
        }
        if(payment.insurance == null ){
          payment.insurance = 0
        }
/*         payment.insurance = 100; // Valor fijo de seguro
        payment.commission = 50; // Valor fijo de comisión */
        this.payments.push(payment);/* borrar */
      });
      // console.log(this.payments)
    });
    
    this.showModalt = true; // Show-Hide Modal Check&
  }

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  hideModal() {
    this.showModal = false;
  } 

  hideModalTabla() {
    this.showModalt = false;
  } 
  exportexcel() {
    this.fileName = "Layout" + '.xlsx';
    const Excel = require('exceljs');
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('base');
    let titulo = ['PLANTILLA DE PAGOS PROGRAMADOS']
    worksheet.addRow(titulo);
    worksheet.mergeCells(1, 1, 1, 2);
    let pagos = ['No. De pagos', this.payments.length]
    worksheet.addRow(pagos);
    let header=['Fecha de pago', 'Capital'];
    worksheet.addRow(header);
    for (const prop in this.payments) {
      worksheet.addRow([
        // this.payments[prop].pay_number,
        new Date(this.payments[prop].payment_date).toLocaleDateString('en-GB'),
        this.payments[prop].capital
      ]);
    } 
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: '.xlsx' });
      const file = blob;
      fs.saveAs(blob, this.fileName);
  });
  }

}
