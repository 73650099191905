import { Component, OnInit } from '@angular/core';
import { AwsService } from 'src/app/services/service.index';

@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.css']
})
export class EmpresasComponent implements OnInit {

  companies = [];
  cols = [];

  constructor(public awsService: AwsService) { }

  ngOnInit(): void {
    this.awsService.getCompanyes().subscribe(resp => {
      this.companies = resp;
      console.log(this.companies)

      this.cols = [

        { field: 'business_name', header: 'Nombre' },
        { field: 'company_rate', header: 'Tasa' },
        { field: 'rfc', header: 'RFC' },
        { field: 'sector', header: 'Sector' },
        { field: 'subsector', header: 'Subsector' },
    ];
    })
  }

}
