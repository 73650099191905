import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable()
export class RegEmpGuard implements CanActivate {
  rid: string;
  roloptions: any[] = [];
  p = false;
  constructor(
    public router: Router,
    public http: HttpClient
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const url = `${environment.URL_SERVICIOS}/reports/user_id/${localStorage.getItem('uid')}/customer_user_association?token=${localStorage.getItem('tkn')}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get( url )
                .map( (resp: any) => {
                  if (resp.length > 0) {
                    this.router.navigate(['/home']);
                    return false
                  } else {
                    return true
                  }
                });
    
    
  }

}