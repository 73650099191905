import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { ResetpwdComponent } from './login/resetpwd/resetpwd.component';
import { CallbackComponent } from './callback/callback.component';
import { AprobarCreditoComponent } from './controlpanel/aprobar-creditos/aprobar-credito.component';
import { FileCallbackComponent } from './callback/file-callback/file-callback.component';

const routes: Routes =[
    { path: 'home',             component: HomeComponent },
    { path: 'user-profile',     component: ProfileComponent },
    { path: 'register',           component: SignupComponent },
    { path: 'landing',          component: LandingComponent },
    { path: 'login',          component: LoginComponent },
    { path: 'resetpwd/:rpwt', component: ResetpwdComponent },
    { path: 'get_callback/:token/:status', component: CallbackComponent },
    { path: 'file_callback/:token/aceptado', component: FileCallbackComponent },
    { path: 'aprobarCredito/:token', component: AprobarCreditoComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
