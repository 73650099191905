import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, timeout } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class CreditosService {

  token: string;
  idUsuario: string;
  path: string =  environment.URL_SERVICIOS.replace('/api/v1','');

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

// CONDICIONES
  crearCondicion( params ) {
    params.secret_key = environment.SECRET_KEY;
    const url = `${ environment.URL_SERVICIOS }/terms?token='${this.token}'`; //  ?option[name]=${ name }&option[description]=${ desc }&option[group]=${ group }&option[url]=${ urlo }&token=1&secret_key=${ environment.SECRET_KEY };

    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getCondiciones() {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${ environment.URL_SERVICIOS }/terms?`;

    return this.http.get( url, { params } ).pipe(
    map( (resp: any) => {return this.crearArregloterms(resp);
    }));

  }

  getCondiciones2() {
    const url = `${this.path}/terms?`;
    return this.http.get(url).pipe(
    // map( (resp: any) => {return this.crearArregloterms(resp);
    map( (resp: any) => {return resp['terms'];
    }));

  }

  crearArregloterms( optionsObj: any) {

    const options: any[] = [];
    const resul: any[] = [];

    if ( optionsObj === null ) { return []; }
    Object.keys ( optionsObj ).forEach( key => {
      const rol: any = optionsObj[key];
      options.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in options[0]) {
  //  console.log( usuarios[0][prop].attributes );
    resul.push( options[0][prop].attributes );
    }

   // console.log(resul);

    return resul;

}

getCondicion( id: string ) {

  const url = `${environment.URL_SERVICIOS}/terms/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloExtService(resp);
    } ));

}

crearArregloExtService( optionObj: any) {

  const option: any[] = [];
  const resul: any[] = [];
 // console.log(usuariosObj);
  if ( optionObj === null ) { return []; }
  Object.keys ( optionObj ).forEach( key => {
    const r: any = optionObj[key];
    option.push( r );
  });
  // tslint:disable-next-line: forin
//  console.log( usuarios[0][prop].attributes );
  resul.push( optionObj.data.attributes );

  return resul;

}

actualizaCondicion(ido: string, params) {
  params.secret_key = environment.SECRET_KEY;
  
 // console.log(params);
const url = `${environment.URL_SERVICIOS}/terms/${ido}?token=${this.token}`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

borrarCondicion(id: string) {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }

  const url = `${ environment.URL_SERVICIOS }/terms/${ id }?`;

  return this.http.delete( url, {params} ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

// PERIODOS DE PAGO

getperiodosPagos() {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${ environment.URL_SERVICIOS }/payment_periods?`;

  return this.http.get( url, { params } ).pipe(
  map( (resp: any) => {return this.crearArregloPeriodosPagos(resp);
  }));

  }

  getperiodosPagos2() {

    const url = `${ this.path }/payment_periods?`;
  
    return this.http.get( url ).pipe(
    // map( (resp: any) => {return this.crearArregloPeriodosPagos(resp['data']);
    map( (resp: any) => { return resp;
    }));
  
    }

crearArregloPeriodosPagos( optionsObj: any) {
  const options: any[] = [];
  const resul: any[] = [];

  if ( optionsObj === null ) { return []; }
  Object.keys ( optionsObj ).forEach( key => {
    const rol: any = optionsObj[key];
    options.push( rol );
  });
  // tslint:disable-next-line: forin
  for (const prop in options[0]) {
//  console.log( usuarios[0][prop].attributes );
  resul.push( options[0][prop] );
  }

//  console.log(resul);

  return resul;

}

borrarPeriodoPago(id: string) {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }

  const url = `${ environment.URL_SERVICIOS }/payment_periods/${ id }?`;

  return this.http.delete( url, {params} ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

getPeriodoPago( id: string ) {

  const url = `${environment.URL_SERVICIOS}/payment_periods/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloExtService(resp);
    } ));

}

actualizaPeriodoPago(ido: string, params) {
  params.secret_key = environment.SECRET_KEY;
  params.token = this.token;
const url = `${environment.URL_SERVICIOS}/payment_periods/${ido}?`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

crearPeriodoPago( params ) {
  params.secret_key = environment.SECRET_KEY;
  params.token = this.token;
  const url = `${ environment.URL_SERVICIOS }/payment_periods?`;

  return this.http.post( url, params ).pipe(
            map( (resp: any) => {
              return resp;
            }));
}

// CALIFICACION CREDITOS

getCalificacionCreditos() {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${ environment.URL_SERVICIOS }/credit_ratings?`;

  return this.http.get( url, { params } ).pipe(
  map( (resp: any) => {return this.crearArregloPeriodosPagos(resp);
  }));

  }

  crearCalificacionCreditos( params ) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
    const url = `${ environment.URL_SERVICIOS }/credit_ratings?`; 
  
    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  borrarCalificacionCredito(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/credit_ratings/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
       } ));
  
  }

  getCalificacionCredito( id: string ) {

    const url = `${environment.URL_SERVICIOS}/credit_ratings/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
    return this.http.get( url ).pipe(
      map ( (resp: any) => { return this.crearArregloExtService(resp);
      } ));
  
  }

  actualizaCalificacionCredito(ido: string, params) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
  const url = `${environment.URL_SERVICIOS}/credit_ratings/${ido}?`;
  
  return this.http.patch( url, params ).pipe(
    map( (resp: any) => { return resp;
    } ));
  }

  // RATES

getRates() {

  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${ environment.URL_SERVICIOS }/rates?`;

  return this.http.get( url, { params } ).pipe(
  map( (resp: any) => {return this.crearArregloPeriodosPagos(resp);
  }));

  }

  crearRate( params ) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
    const url = `${ environment.URL_SERVICIOS }/rates?`; 
  
    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  borrarRate(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/rates/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
       } ));
  
  }

  getRate( id: string ) {

    const url = `${environment.URL_SERVICIOS}/rates/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  
    return this.http.get( url ).pipe(
      map ( (resp: any) => { return this.crearArregloExtService(resp);
      } ));
  
  }

  actualizaRate(ido: string, params) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
  const url = `${environment.URL_SERVICIOS}/rates/${ido}?`;
  
  return this.http.patch( url, params ).pipe(
    map( (resp: any) => { return resp;
    } ));
  }


  // PROJECT REQUEST
  crearRequestProject( params ) {
    params.secret_key = environment.SECRET_KEY;
    params.token = this.token;
    const url = `${ environment.URL_SERVICIOS }/project_requests?`; 
  
    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }
  
  simCredit( info ) {
    let params = {
      secret_key : environment.SECRET_KEY,
      token : this.token,
      sim_credit : info
    }
    const url = `${ environment.URL_SERVICIOS }/sim_credit?`; 
    console.log(url, params);
    return this.http.post( url, {params} ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getProjectRequests(){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${environment.URL_SERVICIOS}/project_requests`;
    
    return this.http.get(url, {params})
  }

  getProjectCustomerReport(projectId){
    const params = {
      project_request_id: projectId,
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${ environment.URL_SERVICIOS }/get_project_request_customer_report?`;
  
    return this.http.get( url, { params } );
  }

  updateRequest(id, update){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }

    const url = `${environment.URL_SERVICIOS}/project_requests/${id}`;
    
    return this.http.patch(url, update, {params})
  }

  createProject(rowData){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }

    const url = `${environment.URL_SERVICIOS}/projects`;
    
    return this.http.post(url, rowData, {params})
  }

  getCredits(){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${environment.URL_SERVICIOS}/customer_credits`;
    
    return this.http.get(url, {params})
  }

  getCredit( id ){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${environment.URL_SERVICIOS}/customer_credits/${id}`;
    
    return this.http.get(url, {params})
  }

  getSignatoriesByCreditId( id ){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY,
      id: id
    }
    const url = `${environment.URL_SERVICIOS}//customer_credits_signatory/customer_credit_id`;
    
    return this.http.get(url, {params})
  }

  updateSignatory(id, credit_id){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY,
      customer_credits_signatories: {
        customer_credit_id: credit_id
      }
    }

    const url = `${environment.URL_SERVICIOS}/customer_credits_signatories/${id}`;
    
    return this.http.patch(url, params)
  }

  getCredit2( id ){
    // const params = {
      // token: this.token,
      // secret_key: environment.SECRET_KEY
    // }
    const url = `${this.path}/get_customer_credits?id=${id}`;
    
    return this.http.get(url)
  }

  customerCredit(params){
    params['token'] = this.token,
    params['secret_key'] = environment.SECRET_KEY

    const url = `${environment.URL_SERVICIOS}/customer_credits`;
    
    return this.http.post(url, params)
  }
  
  customerCreditPaymentsFilterDays(id){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY,
      customer_credit_id: id
    }
    const url = `${environment.URL_SERVICIOS}/get_sim_customer_payments`;
    
    return this.http.get(url, {params})
  }

  customerCreditPayments(id){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    const url = `${environment.URL_SERVICIOS}/customer_credits/${id}/sim_customer_payments`;
    
    return this.http.get(url, {params})
  }

  customerCreditPayments2(id){
    // const params = {
    //   token: this.token,
    //   secret_key: environment.SECRET_KEY
    // }
    const url = `${this.path}/sim_customer_payments?id=${id}`;
    
    return this.http.get(url)
  }

  updateCredit(id, update){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }

    const url = `${environment.URL_SERVICIOS}/customer_credits/${id}`;
    
    return this.http.patch(url, update, {params})
  }

  recoverCreditAnalysis(oldId, newId){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY,
      old_credit_id: oldId,
      new_credit_id: newId
    }

    const url = `${environment.URL_SERVICIOS}/update_analysis`;
    
    return this.http.patch(url, params)
  }

  updateFirmaCredit(signatory_token, status, comment = 'No comments'){
    const params = {
      // token: this.token,
      // secret_key: environment.SECRET_KEY,
      status: status,
      comment: comment
    }

    const url = `${this.path}/customer_credit_signatory?signatory_token=${signatory_token}&status=${status}&comment=${comment}`;
    return this.http.post(url,null)
  }

  traer_customer(id){
    const secret_key = environment.SECRET_KEY;
    const token = this.token;
    const customer_url =`${environment.URL_SERVICIOS}/get_credit_customer_report?customer_credit_id=${id}&token=${token}&secret_key=${secret_key}`;
    return this.http.get(customer_url);
  }

  traer_customer2(id){
    const customer_url =`${this.path}/get_credit_customer_report?customer_credit_id=${id}`;
    return this.http.get(customer_url);
  }

  ext_rates(){
    const secret_key = environment.SECRET_KEY;
    const token = this.token;
    const url =`${environment.URL_SERVICIOS}/ext_rates?token=${token}&secret_key=${secret_key}`;
    return this.http.get(url);
  }

  ext_rates2(){
    // const secret_key = environment.SECRET_KEY;
    // const token = this.token;
    const url =`${this.path}/ext_rates`;
    return this.http.get(url);
  }

  crear_credit_analisis(params){
    params['token'] = this.token;
    params['secret_key'] = environment.SECRET_KEY;
    
    // ENDPOINT QUE CREA UN CREDITALANISIS
    const url = `${environment.URL_SERVICIOS}/credit_analyses?`;
    return this.http.post(url,params)
  }

  actualizar_credit_analisis(    analisis_id,
    // total_amount,
    // accured_liabilities,
    mortagage_loan,
    other_credits,
    car_credit,
    departamental_credit,
    credit_lp,
    credit_cp,
    // balance_due,
    // net_flow,
    // debt,
    // anual_rate,
    // credit_type,
    // payment_capacity,
    // debt_rate,
    // cash_flow,
    // debt_horizon,

    debt_cp,
    departamentalc_debt,
    personalc_debt,
    car_debt,
    mortagage_debt,
    otherc_debt,
    previus_credit,
    // discounts,
    // total_cost
    // valorflujoneto,
    // valorscoreBuro2,
    // previuscredit,
    // valordescuentosexcedentreinta,
    // valorhorizonte,
    // valorultimaClave,
    // valorcreditosolicitado,
    // credit_id,
    // valorfechaReporte: string,
    // valorclaveBaja
    ){
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY,
    }
    // ENDPOINT QUE GENERA AUTOMATICAMENTE UN CREDITALANISIS
    const url = `${environment.URL_SERVICIOS}/credit_analyses/${analisis_id}?credit_analysis[discounts]=""&credit_analysis[previus_credit]=${previus_credit}&credit_analysis[departamental_credit]=${departamental_credit}&credit_analysis[car_credit]=${car_credit}&credit_analysis[other_credits]=${other_credits}&credit_analysis[mortagage_loan]=${mortagage_loan}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.patch(url,null)
  }
 
 tiene_analisis(customer){
  const url = `${environment.URL_SERVICIOS}/credit_analysis/has_credit_analysis?id=${customer.id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }

 tiene_analisis2(customer){
  const url = `${this.path}/credit_analysis/has_credit_analysis?id=${customer.id_credito}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }

 tiene_buro(customer){
  const url = `${environment.URL_SERVICIOS}/credit_bureaus/has_credit_bureau?id=${customer.customer_id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }

 tiene_buro2(customer){
  const url = `${this.path}/credit_bureaus/has_credit_bureau?id=${customer.id_cliente}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }

 trae_buro(id_credito){
  const url = `${environment.URL_SERVICIOS}/credit_bureaus/traer_buro?id=${id_credito}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }

 elimina_buro(id: string){
  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${environment.URL_SERVICIOS}/credit_bureaus/${id}?`;
  return this.http.delete( url, {params}).pipe(
  map( (resp: any) => { return (resp); 
  }));
 }
 
  async genera_reporte_pdf_de_buro(customer){
  const url = `${environment.URL_SERVICIOS}/credit_bureau_pdf?id=${customer.id_credito}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  return this.http.post( url, null).pipe(
  map( (resp: any) => { return (resp); 
  })
  );
 }

  async genera_reporte_pdf_de_buro_arrendamiento(id){
  const url = `${environment.URL_SERVICIOS}/credit_bureau_pdf?id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  return this.http.post( url, null).pipe(
    timeout(600000),
    map( (resp: any) => { 
      return (resp); 
    })
  );
 }


 getUserCotizaciones(id){
  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${environment.URL_SERVICIOS}/get_quote_customer?user_customer_id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  
  return this.http.get(url, {params})
}

 getVendorCotizaciones(id){
  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${environment.URL_SERVICIOS}/get_quote_vendor?user_vendor_id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  
  return this.http.get(url, {params})
}

getCotizacionById(id){
  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${environment.URL_SERVICIOS}/get_quote_credits?id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  
  return this.http.get(url, {params})
}

enviarCotizacion(id, nombre, correo){
  const params = {
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
  const url = `${environment.URL_SERVICIOS}/send_addressed_quotes?quote_id=${id}&name=${nombre}&email=${correo}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;
  
  return this.http.get(url, {params})
}

actualizarDoctor(params){
  params['token'] = this.token;
  params['secret_key'] = environment.SECRET_KEY;
  const url = `${environment.URL_SERVICIOS}/update_customer_data?`;
  return this.http.patch( url, params).pipe(
  map( (resp: any) => { return (resp); 
  }));
}

clienteAsignaCotizacion(params){
  params['token'] = this.token;
  params['secret_key'] = environment.SECRET_KEY;
  const url = `${environment.URL_SERVICIOS}/update_quote_doctor?`;
  return this.http.patch( url, params).pipe(
  map( (resp: any) => { return (resp); 
  }));
}

registroDoctor(params){
  params['token'] = this.token;
  params['secret_key'] = environment.SECRET_KEY;
  const url = `${environment.URL_SERVICIOS}/customer_doctors?`;
  return this.http.post( url, params).pipe(
  map( (resp: any) => { return (resp); 
  }));
}

aceptarCustomerCredit(params){
  params['token'] = this.token;
  params['secret_key'] = environment.SECRET_KEY;
  const url = `${environment.URL_SERVICIOS}/update_quote?`;
  return this.http.patch( url, params).pipe(
  map( (resp: any) => { return (resp); 
  }));
}

updateQuote(id, params){
  params['token'] = this.token;
  params['secret_key'] = environment.SECRET_KEY;
  const url = `${environment.URL_SERVICIOS}/quotes/${id}?`;
  return this.http.patch( url, params).pipe(
  map( (resp: any) => { return (resp); 
  }));
}

}