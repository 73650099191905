import { Component, EventEmitter, OnInit } from '@angular/core';
import { RolesService } from '../../../services/service.index';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-rolesopciones',
  templateUrl: './rolesopciones.component.html',
  styleUrls: ['./rolesopciones.component.css']
})
export class RolesopcionesComponent implements OnInit {
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm = '';
  filaSeleccionada: number | null = null;
  showac = false;
  oprolEmitter = new EventEmitter<Object>();

  constructor(
    public _roleService: RolesService,
    public router: Router,
    public http: HttpClient) { }

  token = localStorage.getItem('token');
  roles: any[] = [];
  cols: any[];
  selectedFac: any[];
  prueba: any[] = [];
  mus = true;
  edopcusu = true;

  ngOnInit() {
    swal2.showLoading();
    this._roleService.getRoles().subscribe( resp => { 
      this.roles = resp; 
      swal2.close(); },
    err => {
      console.clear();
      console.log("ocurrio un error");
      // console.log(err); 
    } );

    // this._roleService.prueba().subscribe( resp => { this.prueba = resp; console.log(this.prueba) } )

    this.cols = [

      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Nombre' },
      { field: 'description', header: 'Descripcion' },
      { field: 'herramientas', header: 'Opciones' }

    ];

  }

  ngOnDestroy(): void { 
    this.oprolEmitter.unsubscribe();

} 

  borraRol( id: string ) {

    swal2.fire({
      title: 'Desea Eliminar el Rol',
      text: 'Seleccionado',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {

        this._roleService.borrarRol( id ).subscribe( () => {

          swal2.fire({
            title: 'El Rol',
            text: 'fue eliminado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.ngOnInit();
            }

          } );

        }, (err) => {
          // swal2.close();
          console.log(err);
          console.clear();
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
        }  );

        }
    });

  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get filteredData(): any[] {

    return this.roles.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) 
    );
  }

  pgone() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  opusu(contid: any, nomcont: any) {
    this.showac = true;
    this.edopcusu = false;
    setTimeout(() => {
      this.oprolEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  pru() {
    this.edopcusu = !this.edopcusu;
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  rgm(even) {
    // console.log(even)
     this.mus = even.clk;
     }

}
