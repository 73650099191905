import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { OpcionesusuariosService, RolesOpcionesService } from '../../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Idd } from '../../../models/usuario.model';
import swal2 from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-asignaopcionesrol',
  templateUrl: './asignaopcionesrol.component.html',
  styleUrls: ['./rolesopciones.component.css']
})
export class AsignaOptionsRolesComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  @Output() childEvent = new EventEmitter();
  optionsfiltradas: any[] = [];
  options: any[] = [];
  datosTabla: Observable<any[]> = new Observable();
  sourceCars: any[];
  rolOptions: any[] = [];
  cols: any[];
  selectedColumns: any[];
  seleccion: Idd;
  colso: any[];
  resul: any[] = [];
  agregar = false;
  seleccionOptions: any[] = [];
  idu: string;
  nombreUsuaOp: string;
  subscription: Subscription;
  itemsSeleccionados: any[] = [];
  itemsSeleccionadosop: any[] = [];
  clk = true;

  constructor( public _optionsservice: OpcionesusuariosService,
               public _rolesoptionsservice: RolesOpcionesService,
               public router: Router,
               public http: HttpClient,
               private route: ActivatedRoute ) {

                }

  ngOnInit() {

    /* this.rolOptions = [];
    this.options = [];
    this.seleccionOptions = [];

    this.idu = this.route.snapshot.paramMap.get('id');
 
    this._rolesoptionsservice.getRol(this.idu).subscribe((resp: string) => { this.nombreUsuaOp = resp; } );

    this.subscription = this._optionsservice.getOptions().subscribe( resp => { 
      this.options = resp; console.log(resp)
      this._rolesoptionsservice.getRolesOptions(this.idu).subscribe( resp2 => {
        this.rolOptions = resp2; 
        console.log('polop', resp2)
        if (this.rolOptions.length === 0) {
          this.seleccionOptions = this.options;
        } else {
          // tslint:disable-next-line: forin
          for (const i in this.options) {
            // tslint:disable-next-line: forin
            for (const j in this.rolOptions) {
              if ( this.options[i].id === this.rolOptions[j].id ) {
                this.rolOptions[j].name = this.options[i].group + ' / ' + this.rolOptions[j].name;
                this.agregar = false;
                break;
              } else {
                this.agregar = true;
              }
            }
            if (this.agregar) {
              this.seleccionOptions.push(this.options[i]);
              this.agregar = false;
            }
          }
        }
        for (const prop in this.seleccionOptions) {
          this.seleccionOptions[prop].name = this.seleccionOptions[prop].group + ' / ' + this.seleccionOptions[prop].name;
        }
      //  console.log(this.usuarioOptions);
      });

    } ); */

    swal2.fire({
      title: 'Cargando',
      allowOutsideClick: false
    });
    swal2.showLoading();
    this.subscribeToParentEmitter();

    this.cols = [
      { field: 'name', header: 'Opciones Disponibles' }
    ];

    this.colso = [
      { field: 'name', header: 'Opciones Asignadas al Rol' }
    ];

  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: string; nomcont: string; }) => {
      (document.getElementById("myInput") as HTMLInputElement).value = '';
      this.filtroDisponibles();
      (document.getElementById("myInput2") as HTMLInputElement).value = '';
      this.filtroAgregadas();
      this.rolOptions = [];
      this.options = [];
      this.seleccionOptions = [];
      this.idu = d.contid
      this.nombreUsuaOp = d.nomcont
      //this._optionsservice.getUsuario(this.idu).subscribe( (resp: string) =>  this.nombreUsuaOp = resp  );
      this.getOptions();
    })
  }

  buscarOpcionesDisponibles() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td = tr[i];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }

  buscarOpcionesAsignadas() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput2");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable2");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
    td = tr[i];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }

  getOptions(){
    this.subscription = this._optionsservice.getOptions().subscribe( resp => { 
      this.options = resp; 
      this._rolesoptionsservice.getRolOptions(this.idu).subscribe( resp2 => {
        resp2['data']['relations']['role_options'].forEach(ro => {
          this.rolOptions.push(ro['attributes']);
        })
        if (this.rolOptions.length === 0) {
          this.seleccionOptions = this.options;
        } else {
          // tslint:disable-next-line: forin

          this.rolOptions.forEach(r => {
            var index = this.options.find(o => o.id == r.option_id);
            r['name'] = index.group + '/' + index.name;
            this.options.splice(this.options.indexOf(index), 1);
          })

        }
      });
      swal2.close();
    } );
  }

  ejecutarAcciones() {
    const observables = this.itemsSeleccionados.map(item =>
      this._rolesoptionsservice.agregaRol( this.idu, item.id )
    );

    forkJoin(observables).subscribe(
      () => {
        this.itemsSeleccionados = [];
        this.agoq();
      },
      error => {
        console.error('Error al llamar al servicio:', error);
      }
    );
  }

  ejecutarAccionesop() {
    const observables = this.itemsSeleccionadosop.map(item =>
      this._rolesoptionsservice.quitarRol( item.id )
    );

    forkJoin(observables).subscribe(
      () => {
        this.itemsSeleccionadosop = [];
        this.agoq();
      },
      error => {
        console.error('Error al llamar al servicio:', error);
      }
    );
  }

  toggleSelectionop(item: any) {
    const index = this.itemsSeleccionadosop.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.itemsSeleccionadosop.splice(index, 1);
    } else {
      this.itemsSeleccionadosop.push(item);
    }
  }

  agoq() {
    (document.getElementById("myInput") as HTMLInputElement).value = '';
    this.filtroDisponibles();
    (document.getElementById("myInput2") as HTMLInputElement).value = '';
    this.filtroAgregadas();
    this.rolOptions = [];
    this.options = [];
    this.seleccionOptions = [];
    //this._optionsservice.getUsuario(this.idu).subscribe( (resp: string) =>  this.nombreUsuaOp = resp  );
    this.getOptions();
  }

  filtroDisponibles() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  filtroAgregadas() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("myInput2");
    filter = input.value.toUpperCase();
    table = document.getElementById("myTable2");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  isSelectedop(item: any): boolean {
    return this.itemsSeleccionadosop.some(i => i.id === item.id);
  }

  isSelected(item: any): boolean {
    return this.itemsSeleccionados.some(i => i.id === item.id);
  }

  toggleSelection(item: any) {
    const index = this.itemsSeleccionados.findIndex(i => i.id === item.id);
    if (index !== -1) {
      this.itemsSeleccionados.splice(index, 1);
    } else {
      this.itemsSeleccionados.push(item);
    }
  }

  cch() {
    this.clk = !this.clk;
    (document.getElementById("myInput") as HTMLInputElement).value = '';
    this.filtroDisponibles();
    (document.getElementById("myInput2") as HTMLInputElement).value = '';
    this.filtroAgregadas();
    this.childEvent.emit({clk: this.clk})
  }

}







