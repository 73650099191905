import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class ParametrosGeneralesService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }


  crearParametro( Description: string, Key: string, Value: string, Table: string, Idtable: string, Usedvalues: string, Documentation: string ) {
    const params = {
      key: Key,
      description: Description,
      value: Value,
      table: Table,
      id_table: Idtable,
      used_values: Usedvalues,
      documentation: Documentation,
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
    console.log(params);
    const url = `${environment.URL_SERVICIOS}/general_parameters?`;


    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getParametros() {

    const url = `${ environment.URL_SERVICIOS }/general_parameters?token=${ this.token }&secret_key=${ environment.SECRET_KEY }`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloParametros(resp);
    }));

    }
  crearArregloParametros( rolesObj: any) {

    const roles: any[] = [];
    const resul: any[] = [];

    if ( rolesObj === null ) { return []; }
    Object.keys ( rolesObj ).forEach( key => {
      const rol: any = rolesObj[key];
      roles.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in roles[0]) {
  //  console.log( usuarios[0][prop].attributes );
    resul.push( roles[0][prop].attributes );
    }

   // console.log(resul);

    return resul;

}

getParametro( id: string ) {

  const url = `${environment.URL_SERVICIOS}/general_parameters/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloParametro(resp);
    } ));

}

crearArregloParametro( rolObj: any) {

  const rol: any[] = [];
  const resul: any[] = [];
 // console.log(usuariosObj);
  if ( rolObj === null ) { return []; }
  Object.keys ( rolObj ).forEach( key => {
    const r: any = rolObj[key];
    rol.push( r );
  });
  // tslint:disable-next-line: forin
//  console.log( usuarios[0][prop].attributes );
  resul.push( rolObj.data.attributes );

 // console.log(resul);

  return resul;

}

actualizaParametro( idp: string, Descripcion: string, Key: string, Valor: string, Tabla: string, IdTabla: string, UsedValues: string, Documentacion: string) {
  const params = {
    key: Key,
    description: Descripcion,
    value: Valor,
    table: Tabla,
    id_table: IdTabla,
    used_values: UsedValues,
    documentation: Documentacion,
    token: this.token,
    secret_key: environment.SECRET_KEY
  }
const url = `${environment.URL_SERVICIOS}/general_parameters/${idp}?`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

borrarParametro(id: string) {

  const url = `${environment.URL_SERVICIOS}/general_parameters/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.delete( url ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

}
