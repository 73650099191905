import { Component, OnInit } from '@angular/core';
import { DocumentosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-serviciosexternos',
  templateUrl: './serviciosexternos.component.html',
  styleUrls: ['./serviciosexternos.component.css']
})
export class ServiciosexternosComponent implements OnInit {
  cols: any[];
  servicios: any[];
  constructor( private documentosService: DocumentosService ) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.documentosService.getExtServices().subscribe( resp => { this.servicios = resp; swal2.close(); } );


this.cols = [
{ field: 'supplier', header: 'Proveedor' },
{ field: 'user', header: 'Usuario' },
{ field: 'api_key', header: 'Api_key' },
{ field: 'token', header: 'Token' },
{ field: 'url', header: 'Url' },
{ field: 'rule', header: 'Regla' },
{ field: 'herramientas', header: 'Herramientas' }

];

}

 borraServicio( id: string ) {
swal2.showLoading();
swal2.fire({
title: 'Desea Eliminar el servicio',
text: 'Seleccionado',
icon: 'question',
showConfirmButton: true,
showCancelButton: true,
allowOutsideClick: false
}). then ( resp => {
if ( resp.value) {

this.documentosService.borrarServicio( id ).subscribe( () => {
swal2.close();
swal2.fire({
 title: 'El servicio',
 text: 'fue eliminado con exito',
 icon: 'success',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {

 if ( res.value ) {
   this.ngOnInit();
 }

} );

}, (err) => {
swal2.close();
console.log(err);
console.clear();
swal2.fire({
 title: 'Ocurrio un error',
 text: '',
 icon: 'error',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {
 if ( res.value ) {
   this.ngOnInit();
 }
} );
} );

}
});

}


  } 


