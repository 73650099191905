import { Component, OnInit } from '@angular/core';
import { CreditosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-condiciones',
  templateUrl: './condiciones.component.html',
  styleUrls: ['./condiciones.component.css']
})
export class CondicionesComponent implements OnInit {
  cols: any[];
  condiciones: any[];
  constructor(private creditosservice: CreditosService) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.creditosservice.getCondiciones().subscribe( resp => { this.condiciones = resp; } );


this.cols = [
{ field: 'key', header: 'Key' },
{ field: 'description', header: 'Descripción' },
{ field: 'credit_limit', header: 'Limite de crédito' },
{ field: 'term_type', header: 'Tipo de condición' },
{ field: 'value', header: 'Valor' },
{ field: 'herramientas', header: 'Herramientas' }

];
  swal2.close();
  }

  borraCondicion( id: string ) {
    swal2.showLoading();
    swal2.fire({
    title: 'Desea Eliminar la condicion',
    text: 'Seleccionada',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
    }). then ( resp => {
    if ( resp.value) {
    
    this.creditosservice.borrarCondicion( id ).subscribe( () => {
    swal2.close();
    swal2.fire({
     title: 'La condicion',
     text: 'fue eliminada con exito',
     icon: 'success',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
    
     if ( res.value ) {
       this.ngOnInit();
     }
    
    } );
    
    }, (err) => {
    swal2.close();
    console.log(err);
    console.clear();
    swal2.fire({
     title: 'Ocurrio un error',
     text: '',
     icon: 'error',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
     if ( res.value ) {
       this.ngOnInit();
     }
    } );
    } );
    
    }
    });
    
    } 
  }

