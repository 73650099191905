import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AwsService, ContribuyentesService, ListasService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-crear-empresa',
  templateUrl: './crear-empresa.component.html',
  styleUrls: ['./crear-empresa.component.css']
})
export class CrearEmpresaComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  @Input() eventEmitter: EventEmitter<Object>;
  legal_entity = new FormGroup ({
    business_name: new FormControl( null),
    rfc: new FormControl( null),
    fiscal_regime: new FormControl( null),
    rug: new FormControl( null),
    fiel: new FormControl( null),
    phone: new FormControl( null),
    mobile: new FormControl( null),
    email: new FormControl( null),
    business_email: new FormControl( null),
    main_activity: new FormControl( null),
  })
  company = new FormGroup ({
    sector: new FormControl( null),
    subsector: new FormControl( null),
    company_rate: new FormControl( null),
    start_date: new FormControl( null),
    credit_limit: new FormControl( null),
    credit_available: new FormControl( null),
    business_name: new FormControl( null),
  })
  company_segments = new FormGroup({
    inicial_payment: new FormControl( null),
    residual_value: new FormControl( null),
    commission: new FormControl( null),
    currency: new FormControl( null),
    company_rate: new FormControl( null),
    key: new FormControl( null),
  })
  contributor = new FormGroup ({
    contributor_type: new FormControl( null),
    bank: new FormControl( null),
    account_number: new FormControl( null),
    clabe: new FormControl( null),
    extra1: new FormControl( null),
  })
  contributor_address = new FormGroup ({
    address_type: new FormControl( null),
    suburb_type: new FormControl( null),
    state_id: new FormControl( null),
    municipality_id: new FormControl( null),
    street: new FormControl( null),
    external_number: new FormControl( null),
    apartment_number: new FormControl( null),
    postal_code: new FormControl( null),
    suburb: new FormControl( null),
    address_reference: new FormControl( null),
  })
  idCompany: string;
  edicion = false;
  empresa: any;
  lists = [];
  fiscal_regime: any;
  address_type: any;
  suburb_type: any;
  company_rate: any;
  bank: any[];
  colonias: any;
  capturaCp: boolean;
  pais: any;
  states: any[];
  countries: any[];
  municipies: any[];

  constructor(private contribuyentesService: ContribuyentesService,
              public router: Router,
              private route: ActivatedRoute,
              private listasService: ListasService,
              private awsService: AwsService) { }

  ngOnInit(): void {
    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
    this.fiscal_regime = this.lists.filter(p => p.domain === 'CONTRIBUTOR_FISCAL_REGIME'); 
    // this.company_rate = this.lists.filter(p => p.domain === 'COMPANY_RATE'); 
    this.bank = this.lists.filter(p => p.domain === 'PERSON_BANK');
    this.address_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
    this.suburb_type = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
    })
    this.awsService.getCountriesD().subscribe( resp => {
      this.countries = resp; 
      this.pais = this.countries[0].id;
      this.buscaEdo(this.countries[0].id);
    } );
    this.subscribeToParentEmitter();
  }
  
  subscribeToParentEmitter(): void { 
    if(this.eventEmitter){
      this.eventEmitter.subscribe((d: { contid: string; }) => {
        this.idCompany = d.contid;
        this.edicion = true;
        this.contribuyentesService.getcompanyInfo(this.idCompany).subscribe(resp => {
          this.empresa = resp[0]; 
          Object.keys(resp[0].contributor.legal_entity).forEach(k => {
            this.legal_entity.patchValue({[k]: resp[0].contributor.legal_entity[k]})
          });
      
          Object.keys(resp[0]).forEach(k => {
            this.company.patchValue({[k]: resp[0][k]})
          });
      
          Object.keys(resp[0].company_segments[0]).forEach(k => {
            this.company_segments.patchValue({[k]: resp[0].company_segments[0][k]})
          });
      
          Object.keys(resp[0].contributor).forEach(k => {
            this.contributor.patchValue({[k]: resp[0].contributor[k]})
          });
      
          Object.keys(resp[0].contributor.contributor_addresses[0]).forEach(k => {
            this.contributor_address.patchValue({[k]: resp[0].contributor.contributor_addresses[0][k]})
          });
          this.getColonias(this.contributor_address.value['postal_code'])
          this.buscaMun(this.contributor_address.value.state_id);
        })
      })
    }
  }

  getColonias(event) {
    this.awsService.getColoniasxcp(event).subscribe(r => {
       this.colonias = r;
       this.capturaCp = false;
       if(this.colonias.length == 0){
         this.capturaCp = true;
       }
     })
   }

   buscaEdo(id) {
    this.awsService.getStatesId(id).subscribe( r => {this.states = r;} )
   }

   buscaMun(id) {
    this.awsService.getMunicipios(id).subscribe( r => {this.municipies = r;} )
   }

  // para validar y limitar el número de decimales en tiempo real
  validateInput(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    const parts = value.split('.');
    if (parts.length === 2) {
      parts[1] = parts[1].substring(0, 2);
      value = parts.join('.');
    }

    input.value = value;
  }

  // Para cuando pierda el foco formatear
  fmCurrency(event: any) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // elimino caracteres no numericos excepto el punto decimal
    value = value.replace(/[^0-9.]/g, '');

    // mantengo solo el primer decimal
    const parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    // formateo y limito a solo dos decimales
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    decimalPart = (decimalPart || '').padEnd(2, '0').substring(0, 2);

    // actualizo visualmente
    input.value = `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
  }

  cerraroregusu() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }

  registrarEmpresa(){
    this.company.value.business_name = this.legal_entity.value.business_name;
    var params = {
      token: '',
      secret_key: '',
      contributor: this.contributor.value,
      contributor_address: this.contributor_address.value,
      legal_entity: this.legal_entity.value,
      company: this.company.value,
      company_segments: [this.company_segments.value]
    }

    console.log(params);

    if(!this.edicion){  
      this.contribuyentesService.crearCadena(params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Empresa creada correctamente', 'success');
        this.cerraroregusu();
      }, err => {
        console.log('error', err);
        try{
          swal2.fire('Atención', err.error.errors[0], 'info');
        }catch{
          swal2.fire('Atención', 'Algo salió mal', 'info');
        }
      })
    }else{
      console.log(this.empresa);
      params['contributor_id'] = this.empresa.contributor.id;
      params['contributor_address_id'] = this.empresa.contributor.contributor_addresses[0].id;
      params['legal_entity_id'] = this.empresa.contributor.legal_entity.id;
      params['company_id'] = this.empresa.id;
      params['company_segment_id'] = this.empresa.company_segments[0].id;

      this.contribuyentesService.patchCompanyInfo(params).subscribe(resp => {
        console.log(resp);
        swal2.fire('Éxito', 'Empresa creada correctamente', 'success');
        this.cerraroregusu();
      }, err => {
        console.log('error', err);
        try{
          swal2.fire('Atención', err.error.errors[0], 'info');
        }catch{
          swal2.fire('Atención', 'Algo salió mal', 'info');
        }
      })
    }
  }

}
