import { Component, OnInit } from '@angular/core';
import { CreditosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-periodospago',
  templateUrl: './periodospago.component.html',
  styleUrls: ['./periodospago.component.css']
})
export class PeriodospagoComponent implements OnInit {
  cols: any[];
  periodospago: any[];
  constructor(private creditosservice: CreditosService) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.creditosservice.getperiodosPagos().subscribe( resp => { this.periodospago = resp;} );

this.cols = [
  
{ field: 'key', header: 'Key' },
{ field: 'description', header: 'Descripcion' },
{ field: 'pp_type', header: 'Tipo periodo pago' },
{ field: 'value', header: 'Valor' },
{ field: 'herramientas', header: 'Herramientas' }
  
];
swal2.close();
  }

  borraPeriodoPago( id: string ) {
    swal2.showLoading();
    swal2.fire({
    title: 'Desea Eliminar el perido',
    text: 'Seleccionado',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
    }). then ( resp => {
    if ( resp.value) {
    
    this.creditosservice.borrarPeriodoPago( id ).subscribe( () => {
    swal2.close();
    swal2.fire({
     title: 'El periodo',
     text: 'fue eliminado con exito',
     icon: 'success',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
    
     if ( res.value ) {
       this.ngOnInit();
     }
    
    } );
    
    }, (err) => {
    swal2.close();
    console.log(err);
    console.clear();
    swal2.fire({
     title: 'Ocurrio un error',
     text: '',
     icon: 'error',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
     if ( res.value ) {
       this.ngOnInit();
     }
    } );
    } );
    
    }
    });
    
    }   

}
