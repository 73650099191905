import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import Swal2 from 'sweetalert2'; 
import { CreditosService } from '../../services/creditos/creditos.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import swal2 from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UsuarioService } from 'src/app/services/service.index';
import * as moment from 'moment';

@Component({
  selector: 'app-creditanalisis',
  templateUrl: './creditanalisis.component.html',
  styleUrls: ['./creditanalisis.component.scss']
})
export class CreditAnalisisComponent implements OnInit {
  credit_id: string;
  customer: any;
  doc: any;
  buroStatus = false;
  analysisStatus = false;
  doctor: FormGroup;
  status_de_Buro: any;
  buro: any;
  reporte_buro_pdf: any;
  buroPdfStatus: boolean;
  fechaReporte: string;
  claveMop: string;
  ultimaClave: string;
  claveBaja: string;
  saldosVencidos: any;
  scoreBC: string;
  scoreBC2: string;
  cargado = false;
  customer_credit = {};
  calificacionBC: any;

  constructor(
    public router: Router,
    private routee: ActivatedRoute,
    private creditosService: CreditosService,
    public http: HttpClient,
    private usuarioService: UsuarioService) { }

token = localStorage.getItem('token');

ngOnInit() {
  this.credit_id = this.routee.snapshot.paramMap.get('id');

  Swal2.showLoading();
  // TRAE DATOS NECESARIOS DEL CREDITO PARA HACER EL ANALISIS / TRAE DATOS DEL BURO QUE SE NECESITAN
  // TRAE CUSTOMER CREDIT CON EL CREDIT_ID
  this.creditosService.traer_customer(this.credit_id).subscribe(customer => {
    this.customer = customer[0];
    this.doc = this.customer.quote.user_customer.doctors[0];
    this.doctor = new FormGroup({
      fee: new FormControl( this.doc.fee, Validators.required),
      average_fee: new FormControl( this.doc.average_fee, Validators.required),
      extra_incomes: new FormControl( this.doc.extra_incomes, Validators.required),
      monthly_income: new FormControl( this.doc.monthly_income, Validators.required),
      surgery_income: new FormControl( this.doc.surgery_income, Validators.required),
      own_consultory: new FormControl( this.doc.own_consultory, Validators.required),
      payment_rent: new FormControl( this.doc.payment_rent, Validators.required),
      month_patients: new FormControl( this.doc.month_patients, Validators.required),
      payment_capacity: new FormControl( null, Validators.required),
    })
    this.creditosService.getCredit(this.credit_id).subscribe(c => {
      this.customer_credit = c['data']['attributes'];
    })
    this.creditosService.tiene_analisis(this.customer).subscribe(analysis => {
      this.analysisStatus = analysis.status;
      this.cargado = true;
    })
    this.creditosService.tiene_buro(this.customer).subscribe(buro => {
      this.buroStatus = buro.status;
      if (this.buroStatus){
        this.buro = buro.credit_bureau;
        this.reporte_buro_pdf = this.buro['extra1']
        if (this.reporte_buro_pdf != null){
          this.buroPdfStatus = true
        }
        // console.log(this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos'])
        // console.log(this.buro['bureau_report']['results'])
        var fecharepor;
        if(this.buro['bureau_info'] != null){
          const d = new Date(this.buro['updated_at']);
          d.setMinutes( d.getMinutes() + d.getTimezoneOffset() );
          let month = '' + (d.getMonth() + 1);
          let day = '' + d.getDate();
          const year = d.getFullYear();
        
          if (month.length < 2) {
            month = '0' + month;
          }
          if (day.length < 2) {
            day = '0' + day;
          }
          fecharepor = [day, month, year].join('-');
          this.saldosVencidos = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ResumenReporte']['ResumenReporte'][0]['TotalSaldosVencidosPagosFijos']
          this.calificacionBC = this.buro['bureau_report']['results'][1]['response']['return']['Personas']['Persona'][0]['ScoreBuroCredito']['ScoreBC'][0]['ValorScore']
        }else{
          fecharepor = moment(this.buro['bureau_report']['respuesta']['persona']['hawkAlertConsulta'][0]['fechaReporte'],"D/M/yyyy").format("DD/MM/YYYY");
          this.saldosVencidos = this.buro.bureau_report.respuesta.persona.resumenReporte[0].totalSaldosVencidosPagosFijos
          this.calificacionBC = this.buro.bureau_report.respuesta.persona.scoreBuroCredito[0].valorScore
        }
        this.fechaReporte  = fecharepor;
        this.claveMop = 'NO'
        this.ultimaClave = '1'
        this.claveBaja = '1'
        
        if (this.calificacionBC <= 550 ){
          this.scoreBC = 'BAJA'  + ' (' + this.calificacionBC +')';
          this.scoreBC2 = 'BAJA'  + ' (' + this.calificacionBC +')';
        }else if (this.calificacionBC  > 551 && this.calificacionBC  < 649 ){
          this.scoreBC = 'REGULAR' + ' (' + this.calificacionBC +')';
          this.scoreBC2 = 'REGULAR' + ' (' + this.calificacionBC +')';
        } else if(this.calificacionBC > 650 && this.calificacionBC  < 749){
          this.scoreBC = 'BUENA'  + ' (' + this.calificacionBC +')';
          this.scoreBC2 = 'BUENA'  + ' (' + this.calificacionBC +')';
        } else if (this.calificacionBC  > 750){
          this.scoreBC = 'EXCELENTE'  + ' (' + this.calificacionBC +')';
          this.scoreBC2 = 'EXCELENTE'  + ' (' + this.calificacionBC +')';
        }
      }else {
        if(this.cargado){
          document.getElementById('guardarAnalisisBtn').setAttribute("disabled", "disabled");
          document.getElementById('guardarAnalisisBtn').setAttribute("hidden", "hidden");
        }
      }
    })
  })
  Swal2.close();
}

confirmarConsultaBuro(){
  Swal.fire({
    title: 'Estás seguro?',
    text: "Estás a punto de realizar una consulta al servicio de Buró de Crédito!" + ' ' + "Asegurate de contar con la autorización de consulta de historial del cliente.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Si, consultar Historial de Crédito!',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      this.traerBuro()
    }
  })
}

traerBuro(){

  // deshabilitar boton de buro
  document.getElementById('buro_btn').setAttribute("disabled", "disabled");
  document.getElementById('buro_btn').setAttribute("hidden", "hidden");
  Swal2.showLoading();
  this.creditosService.trae_buro(this.credit_id).subscribe(buro => {
    this.buroStatus = buro.status;
    if (this.buroStatus){
      this.buro = buro.credit_bureau;
        Swal.fire({
          title: 'Consulta Exitosa',
          text: '',
          icon: 'success',
          showConfirmButton: true,
          showCancelButton: false,
          allowOutsideClick: false
        }). then ( res => {
            window.location.reload();
        } );
    }else {
      this.status_de_Buro = buro.status_de_Buro;
      const mensaje = buro.message;
      if(mensaje == "El cliente cuenta con codigo especial de SCORE"){
          Swal.fire({
            icon: 'error',
            title: 'Cliente con codigo especial de SCORE',
            text: 'SCORE de Buró: ' + buro.SCORE + ' ' + this.codigoExclusion(buro.SCORE),
            footer: '<strong>Revise información del cliente </strong>',
            showConfirmButton: true,
          })
      }else if(mensaje == "El cliente no cuenta con registros en Buró de Crédito"){
        Swal.fire({
          title: 'Información no encontrada',
          text: buro.status_de_Buro + ' ' + buro.message,
          icon: 'error',
          showConfirmButton: true,
        })
      }else if(mensaje == "Credenciales de buró expiradas en Moffin"){
        Swal.fire({
          title: 'Contacte al administrador',
          text: buro.message ,
          icon: 'error',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: '¿Eliminar registro?',
          cancelButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            this.eliminaBuro(buro.id)
          }else{
            window.location.reload();
          }
        })
      }
      else
      {
        Swal.fire({
          title: 'Error en Buró de Crédito',
          text: buro.message + this.status_de_Buro,
          icon: 'error',
          showConfirmButton: true,
        })
      }
    }
  })
}

eliminaBuro(id){
  this.creditosService.elimina_buro(id).subscribe(resp => {
    this.buroStatus = resp.status;
    if (this.buroStatus){
        Swal.fire({
          title: 'Registro de buró eliminado correctamente',
          text: '',
          icon: 'success',
        }). then ( res => {
            window.location.reload();
        } );
    }
  })
}

codigoExclusion(codigo){
  if(codigo == '-001'){
    return 'Consumidor Fallecido'
  }else if(codigo == '-005'){
    return 'Un archivo de crédito donde todas las cuentas están actualmente cerradas y por lo menos una cuenta con más de 90 días o más de morosidad. MOP>=04.'
  }else if(codigo == '-006'){
    return 'Un archivo de crédito donde todas las cuentas han sido abiertas en los últimos 6 meses y por lo menos una cuenta con más de 60 días o más de morosidad MOP>=03.'
  }else if(codigo == '-007'){
    return 'Un archivo de crédito donde todas las cuentas han sido abiertas en los últimos 6 meses y por lo menos una cuenta tiene 30 días o más de morosidad. MOP>=02.'
  }else if(codigo == '-008'){
    return 'El archivo de crédito no tiene por lo menos una cuenta actualizada en los últimos 12 meses o por lo menos una cuenta abierta en los últimos 6 meses ("Criterios mínimos de calificación").'
  }else if(codigo == '-009'){
    return 'Expediente sin cuentas para cálculo del Score Buró Histórico'
  }
}

 async generar_reporte_pdf_buro_credito(){
  Swal2.showLoading();
  await (await this.creditosService.genera_reporte_pdf_de_buro_arrendamiento(this.credit_id)).toPromise().then(resp =>{
    console.log(resp);
    Swal.fire({
      title: 'Se ha generado el reporte',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      // if ( res.value ) {
        // console.log("respuesta")
        // console.log(res)
        window.location.reload();
        // this.router.navigate(['/panelcontrol/opciones']);
      // }
    })
  }).catch(async err => {
    console.log(err);
    try{
      Swal.fire({
        title: 'Atención',
        text: err.error.error,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
          // if ( res.value ) {
            // console.log("respuesta")
            // console.log(res)
            window.location.reload();
            // this.router.navigate(['/panelcontrol/opciones']);
          // }
      })

    }catch(error){
      Swal.fire({
        title: 'Atención',
        text: 'Algo salió mal',
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
          // if ( res.value ) {
            // console.log("respuesta")
            // console.log(res)
            window.location.reload();
            // this.router.navigate(['/panelcontrol/opciones']);
          // }
      })
    }
  })

}

crearCreditAnalisis(parametros){
  // crear_credit_analisis
  Swal2.showLoading();
  //  MANDA SOLO LOS CAMPOS NECESARIOS
  var params = {};
  var credit_analysis = {
    customer_credit_id: this.credit_id,
    payment_capacity: this.doctor.value.payment_capacity
  };
  params['credit_analysis'] = credit_analysis;
  this.creditosService.crear_credit_analisis(params).subscribe( () => {
    Swal.fire({
      title: 'Creación de análisis exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        // console.log("respuesta")
        // console.log(res)
        this.creditosService.updateCredit(this.credit_id, parametros).subscribe(resp => {
          swal2.fire('Éxito', 'Credito actualizado correctamente', 'success');
          // this.router.navigate(['/panelcontrol/aprobarCreditos']);
          window.location.reload();
        }, )
      }
    } );
  }, (err) => {             
    console.log(err);
    Swal.fire(
      'Error al crear Analisis',
      'Error',
      'error'
      );
  } );  
}

actualizarCreditAnalisis(){
  /* // crear_credit_analisis
  Swal2.showLoading();
  // console.log("DENTRO DE ACTIALIZAR actualizarCreditAnalisis")
  const valormortagage_loan = (<HTMLInputElement>document.getElementById('mortagage_loan')).value;
  const valorother_credits = (<HTMLInputElement>document.getElementById('other_credits')).value;
  const valorcar_credit = (<HTMLInputElement>document.getElementById('car_credit')).value;
  const valordepartamental_credit = (<HTMLInputElement>document.getElementById('departamental_credit')).value;
  const valorcredit_lp = (<HTMLInputElement>document.getElementById('credit_lp')).value;
  const valorcredit_cp = (<HTMLInputElement>document.getElementById('credit_cp')).value;
  const valordebt_cp = (<HTMLInputElement>document.getElementById('debt_cp')).value;
  const valordepartamentalc_debt = (<HTMLInputElement>document.getElementById('departamentalc_debt')).value;
  const valorpersonalc_debt = (<HTMLInputElement>document.getElementById('personalc_debt')).value;
  const valorcar_debt = (<HTMLInputElement>document.getElementById('car_debt')).value;
  const valormortagage_debt = (<HTMLInputElement>document.getElementById('mortagage_debt')).value;
  const valorotherc_debt = (<HTMLInputElement>document.getElementById('otherc_debt')).value;
  const valorprevius_credit = (<HTMLInputElement>document.getElementById('previus_credit')).value;
  // const valordiscounts = (<HTMLInputElement>document.getElementById('discounts')).value;
  //  MANDA TODOS LOS DATOS RELEVANTES DE LA TABLA CREDIT ANALISIS
  //  METODO QUE ACTUALICE EL ANALISIS
  this.creditosService.actualizar_credit_analisis(
    this.analisis_id,
    valormortagage_loan,
    valorother_credits,
    valorcar_credit,
    valordepartamental_credit,
    valorcredit_lp,
    valorcredit_cp,
    valordebt_cp,
    valordepartamentalc_debt,
    valorpersonalc_debt,
    valorcar_debt,
    valormortagage_debt,
    valorotherc_debt,
    valorprevius_credit,
    // valordiscounts,
    ).subscribe( () => {
    Swal.fire({
      title: 'Analisis Actualizado',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        // console.log("respuesta")
        // console.log(res)
        // this.router.navigate(['/panelcontrol/opciones']);
      }
    } );
  }, (err) => {
    console.log(err);
  Swal.fire(
    'Error al crear Analisis',
    'Error',
    'error'
      );
  } );  */
}

esCero(valor){
  if(!valor){
    return '0';
  }
  else {
    return valor;
  }
}

formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

procesarCredito(status){
  var params = {}
  var customer_credit = {
    status: status
  }

  params['customer_credit'] = customer_credit;

  this.crearCreditAnalisis(params)
  
  
}

  generarDocumentos(){
    swal2.showLoading();
    this.usuarioService.generarDocumentosDoctor(this.credit_id).subscribe(resp => {
      Swal.fire({
        title: 'Creación exitosa',
        html: `<a title="Descargar" class="btn btn-white btn-icon mb-3 mb-sm-0" href="${resp['data']['attributes']['documents']}" download>
                  <span class="btn-inner--icon"><i class="material-icons">file_download</i> Descargar documento</span>
              </a>`,
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.router.navigate(['/panelcontrol/aprobarCreditos']);
        }
      } );
    })
  }

}
