import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import Swal2 from 'sweetalert2';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-file-callback',
  templateUrl: './file-callback.component.html',
  styles: [
  ]
})
export class FileCallbackComponent implements OnInit {
  token: string;
  tokenExpiry: any[] = [];
  bandera: boolean;
  status: string;
  datosusuario: any[] = [];
  recuperapass = false;

  constructor( private route: ActivatedRoute,
               private usuarioservice: UsuarioService,
               public router: Router ) { }

  ngOnInit(): void {
    this.token = this.route.snapshot.paramMap.get('token');
    
    this.usuarioservice.fileToken(this.token).subscribe(resp => {
      console.log(resp);
      if(resp.status){
        swal2.fire(resp.message, '', 'success');             
        setTimeout(() => {
          this.router.navigate(['/home']);
        }, 1800);
      }else{
        swal2.fire('Atención', resp.message, 'info');             
        setTimeout(() => {
          this.router.navigate(['/home']);
        }, 1800);
      }
    }, err => {
      console.log(err);
      
    })

    }

}
