import { Component, OnInit } from '@angular/core';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-carros',
  templateUrl: './carros.component.html',
  styles: [
  ]
})
export class CarrosComponent implements OnInit {
  usuarios: any[] = [];
  cols: any[];
  nombre = '';
  constructor( private usuariosservice: UsuarioService,
                private catalogosService: CatalogosService ) {
  }

  ngOnInit(): void {
    
    this.catalogosService.getCars().subscribe( resp => { 
      console.log(resp);
      this.usuarios = resp;
    } );
    this.cols = [

      { field: 'car_key', header: 'Clave'}, 
      { field: 'brand', header: 'Marca'}, 
      { field: 'model', header: 'Modelo'}, 
      { field: 'line', header: 'Línea'}, 
      { field: 'car_type', header: 'Tipo'}, 
      { field: 'car_class', header: 'Clase'}, 
      { field: 'capacity', header: 'Capacidad'}, 
      { field: 'cylinder_number', header: 'Cilindros'}, 
      { field: 'door_number', header: 'Puertas'}, 
      { field: 'fuel', header: 'Combustible'}, 
  ];

  }

  borrarCar(id){
    this.catalogosService.borrarCar(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Automóvil eliminado correctamente', 'success');
      this.ngOnInit();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

   
  

}

