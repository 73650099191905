import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-maincliente',
  templateUrl: './maincliente.component.html',
  styleUrls: ['./maincliente.component.css']
})
export class MainclienteComponent implements OnInit {
  idu: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.idu = this.route.snapshot.paramMap.get('id');
  }

}
