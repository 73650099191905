import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/services/service.index';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal2 from 'sweetalert2'; 
@Component({
  selector: 'app-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.css']
})
export class ResetpwdComponent implements OnInit {
  rpwt: string;
  datosusuario: any[] = [];
  recuperapass = false;
  forma: FormGroup;
  usuario: any[] = [];
  //para validaciones//
  may = false;
  min = false;
  dig = false;
  lon = false;
  ////////////////////
  constructor( private route: ActivatedRoute,
               private usuarioservice: UsuarioService,
               public router: Router ) { }

  ngOnInit(): void {

    this.forma = new FormGroup({

      password: new FormControl( null , Validators.required ),
      password2: new FormControl( null , Validators.required ),

    }, { validators: this.sonIguales( 'password', 'password2' )  } );

    this.rpwt = this.route.snapshot.paramMap.get('rpwt');

    this.usuarioservice.getUsuarioRPWT(this.rpwt).subscribe( resp => {this.datosusuario = resp; 
    if ( this.datosusuario[0].status === 'SV' ) {
      this.recuperapass = true;
      Swal2.showLoading();
      setTimeout(()=>{
        const params = {
         // token: '',
          secret_key: '',
          status: 'AC'
        }
        this.usuarioservice.actualizaUsuarioStatus(this.datosusuario[0].id, params).subscribe( resp => {
          Swal2.fire({
            title: 'Correo verificado con exito',
            text: 'Continuar',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              Swal2.fire({
                title: 'Genere nueva contraseña a continuación',
                icon: 'success',
                showConfirmButton: true,
                showCancelButton: false,
                allowOutsideClick: false
              }). then ( res => {document.getElementById('password').focus();});
              // this.router.navigate(['/home']);
            }

          } );

        }, (err) => {
          Swal2.close();
          console.log(err);
         // console.clear();
          Swal2.fire({
            title: 'Ocurrio un error al verificar su correo o la liga ya expiro',
            text: '',
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.router.navigate(['/home']);
            }
          } );
        } )                           
        Swal2.close();
   }, 1000);
    } else {
      this.recuperapass = true;

    }
    },
    (err) => {
      console.clear();
      Swal2.fire({
        title: 'El token ha expirado',
        text: 'Favor de intentarlo de nuevo',
        icon: 'error',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.router.navigate(['/home']);
        }
      }
      )}
    ); 
  }

  ch(val) {
    if (val.length === 0) {
      this.may = false;
      this.min = false;
      this.dig = false;
      this.lon = false;
    } else {
      if (val.length >= 8 && val.length <= 16) {
        this.lon = true
      } else {
        this.lon = false
      }
      for (var i=0; i<val.length; i++){
        if (val.charAt(i) == val.charAt(i).toUpperCase() && val.charAt(i).match(/[a-z]/i)){
          this.may = true
          break
        } else {
          this.may = false
        }
      }
      for (var i=0; i<val.length; i++){
        if (val.charAt(i) == val.charAt(i).toLowerCase() && val.charAt(i).match(/[A-Z]/i)){
          this.min = true
          break
        } else {
          this.min = false
        }
      }
      for (var i=0; i<val.length; i++){
        if (val.charAt(i) == val.charAt(i).match(/[0-9]/i)){
          this.dig = true
          break
        } else {
          this.dig = false
        }
      }
    }
    
  }

  validar_email( email )
  {
    const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email) ? true : false;
  }

  valida_password(pass) {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,16})");
   // const regex = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/;
    return strongRegex.test( pass ) ? true : false;
  }

  sonIguales( campo1: string, campo2: string ) {

  return ( group: FormGroup ) => {

    let pass1 = group.controls[campo1].value;
    let pass2 = group.controls[campo2].value;

    if ( pass1 === pass2 ) {
      return null;
    }
      return {
      sonIguales: true
      };
    };
  }

  resetpassword() {
    if ( this.valida_password( this.forma.value.password ) ) {
      // el correo es valido
      } else {
        Swal2.fire(
          'Verifique de nuevo',
          'La contraseña no tiene el formato correcto',
          'error'
        );
        return null;
      }
  
    if ( this.forma.value.password !== this.forma.value.password2 ) {

    Swal2.fire(
      'Error al crear usuario',
      'Las contraseñas no son iguales',
      'error'
    );
    return null;
    }

    if ( this.forma.invalid ) {
      return;
    }
    this.usuarioservice.getResetPasswordToken(this.rpwt).subscribe( (resp:any) => {  
      if ( resp.status == true ) {
        this.usuarioservice.actualizaUsuarioPassWord( this.forma.value.password, this.rpwt )
          .subscribe( (resp) => {
            if ( resp.status == true ) {
            Swal2.fire(
              'Cambio de contraseña',
              'Exitosa',
              'success'
            );
            this.router.navigate(['/login']);
          } else {
            Swal2.fire(
              'Hubo un error',
              resp.message,
              'error'
            );
            this.router.navigate(['/login']);
          }
          }, err => { console.log(err);
          Swal2.fire(
            'Error al cambiar contraseña',
            'Intente de nuevo',
            'error'
          ); this.router.navigate(['/login']);
          });
          console.log(resp)
        } else {
          Swal2.fire(
            'Token sin vigencia',
             resp.message,
            'error'
          );this.router.navigate(['/login']);
        }
      });
  }
}
