import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { OpcionesService } from 'src/app/services/service.index';
@Component({
  selector: 'app-opciones',
  templateUrl: './opciones.component.html',
  styleUrls: ['./opciones.component.css']
})
export class OpcionesComponent implements OnInit {

  constructor(
    public _optionsservice: OpcionesService,
    public router: Router,
    public http: HttpClient ) { }

token = localStorage.getItem('token');
options: any[] = [];
cols: any[];
selectedFac: any[];
tieneacceso = false;
ngOnInit() {
swal2.showLoading();
this._optionsservice.getOptions().subscribe( resp => { this.options = resp; swal2.close(); },
err => {
  console.clear();
  console.log("ocurrio un error");
  // console.log(err); 
} );

this.cols = [

{ field: 'name', header: 'Nombre' },
{ field: 'description', header: 'Descripcion' },
{ field: 'group', header: 'Grupo' },
{ field: 'url', header: 'URL' },
{ field: 'herramientas', header: 'Herramientas' }

];

}

borraOption( id: string ) {
swal2.showLoading();
swal2.fire({
title: 'Desea Eliminar la opcion',
text: 'Seleccionada',
icon: 'question',
showConfirmButton: true,
showCancelButton: true,
allowOutsideClick: false
}). then ( resp => {
if ( resp.value) {

this._optionsservice.borrarOption( id ).subscribe( () => {
swal2.close();
swal2.fire({
 title: 'La opcion',
 text: 'fue eliminada con exito',
 icon: 'success',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {

 if ( res.value ) {
   this.ngOnInit();
 }

} );

}, (err) => {
swal2.close();
console.log(err);
console.clear();
swal2.fire({
 title: 'Ocurrio un error',
 text: '',
 icon: 'error',
 showConfirmButton: true,
 showCancelButton: false,
 allowOutsideClick: false
}). then ( res => {
 if ( res.value ) {
   this.ngOnInit();
 }
} );
} );

}
});

}

}
