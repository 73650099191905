import { Component, OnInit } from '@angular/core';
import { RegistrovalidacionService } from '../../../../services/registrovalidacion/registrovalidacion.service';
import { TuidentidadService } from '../../../../services/tuidentidad/tuidentidad.service';

@Component({
  selector: 'app-registrodocumentos',
  templateUrl: './registrodocumentos.component.html',
  styleUrls: ['./registrodocumentos.component.css']
})
export class RegistrodocumentosComponent implements OnInit {
  idu: string;
  documentos: any[];
  fileToUpload: File = null;
  imgURL: any;
  public imagePath;
  constructor( private registrovalidacion: RegistrovalidacionService,
               private tuidentidad: TuidentidadService ) { }

  ngOnInit(): void {
    this.idu = '';
    this.documentos = [];
    this.idu = localStorage.getItem('uid');
    this.registrovalidacion.getusuario(this.idu).subscribe( usuario => {
      if (usuario.data.relations.customers.length > 0) {
        this.registrovalidacion.getContribuyente(usuario.data.relations.customers[0].attributes.contributor_id).subscribe( resp => {
        
          for ( const prop in resp.data.relations.contributor_documents ) {
            
            this.documentos.push(resp.data.relations.contributor_documents[prop].attributes);
          }
        
        } )
      } else if ( usuario.data.relations.funders.length > 0 ) {
        this.registrovalidacion.getContribuyente(usuario.data.relations.funders[0].attributes.contributor_id).subscribe( resp => {
          
            for ( const prop in resp.data.relations.contributor_documents ) {
              
              this.documentos.push(resp.data.relations.contributor_documents[prop].attributes);
            }
          
          } )
      }
      console.log(this.documentos);
      } )
  }

  handleFileInputFront(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(files)
    console.log(this.fileToUpload);
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
      console.log(this.imgURL);
    }
}

handleFileInputBack(files: FileList) {
  this.fileToUpload = files.item(0);
  console.log(files)
  console.log(this.fileToUpload);
  var reader = new FileReader();
  this.imagePath = files;
  reader.readAsDataURL(files[0]); 
  reader.onload = (_event) => { 
    this.imgURL = reader.result; 
    console.log(this.imgURL);
  }
}

ineservice() {
  const params = {
     checkInfo: true,
    checkQuality: true,
    checkPatterns: true,
    checkCurp: true,
    checkFace: true,
    debugRenapo: false,
    FrontFile: this.fileToUpload,
    BackFile: ''
  }
  console.log(params)
  //this.tuidentidad.validation_success(params).subscribe( resp => console.log(resp) );
}

}
