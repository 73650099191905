import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ContribuyentesService } from '../../../services/registrocontribuyente/contribuyente.service';
import swal2 from 'sweetalert2';
import { AwsService } from '../../../services/awsser/aws.service';
import { ListasService } from 'src/app/services/service.index';

@Component({
  selector: 'app-actualizar-empleados-cadenas',
  templateUrl: './actualizar-empleados-cadenas.component.html',
  styleUrls: ['./actualizar-empleados-cadenas.component.css']
})
export class ActualizarEmpleadosCadenasComponent implements OnInit {

  contributorId: string = '';
  type: string = '';
  url: string = '';
  forma: FormGroup;
  keysCustomer = [
    {value: 'Nombre', key: 'name'},
    {value: 'Estado', key: 'status'},
    {value: 'Adjunto', key: 'company_id'},
    {value: 'Tipo de Archivo', key: 'file_type_id'},
    {value: 'Jefe Inmediato', key: 'inmediate_superior'},
    {value: 'Salario', key: 'salary'},
    {value: 'Periodicidad', key: 'salary_period'},
    {value: 'Extra 1', key: 'extra1'},
    {value: 'Extra 2', key: 'extra2'},
    {value: 'Extra 3', key: 'extra3'},
  ];
  
  keysCompany = [
    {value: 'Nombre', key: 'business_name'},
    {value: 'Límite de crédito', key: 'credit_limit'},
    {value: 'Adjunto', key: 'document'},
    {value: 'Sector', key: 'sector'},
    {value: 'Subsector', key: 'subsector'}
  ];

  miFormulario: FormGroup = this.fb.group({
    id: [''],
    attached: [''],
    company_id: [''],
    contributor_id: [''],
    file_type_id: [''],
    inmediate_superior: [''],
    name: [''],
    salary: [''],
    salary_period: [''],
    seniority: [''],
    status: [''],
    extra1: [''],
    extra2: [''],
    extra3: [''],
    business_name: [''],
    credit_available: [''],
    credit_limit: [''],
    document: [''],
    sector: [''],
    subsector: ['']
  })

  keysLegalEntities = [
    {value: 'Nombre de la Empresa', key: 'business_name'},
    {value: 'Correo Electrónico', key: 'email'},
    {value: 'Correo Electrónico Corporativo', key: 'business_email'},
    {value: 'Teléfono Móvil', key: 'mobile'},
    {value: 'Teléfono', key: 'phone'},
    // {value: 'Actividad Principal', key: 'main_activity'},
    // {value: 'Regimen Fiscal', key: 'fiscal_regime'},
    // {value: 'Fiel', key: 'fiel'},
    {value: 'RFC', key: 'rfc'},
    {value: 'RUG', key: 'rug'},
  ];

  keysPeople = [
    {key: 'first_name', value: 'Nombre'},
    {key: 'last_name', value: 'Apellido Paterno'},
    {key: 'second_last_name', value: 'Apellido Materno'},
    // {key: 'birth_country', value: 'País de Nacimiento'},
    // {key: 'birthdate', value: 'Fecha de Nacimiento'},
    // {key: 'birthplace', value: 'Lugar de nacimiento'},
    // {key: 'gender', value: 'Género'},
    {key: 'nationality', value: 'Nacionalidad'},
    {key: 'mobile', value: 'Teléfono Móvil'},
    {key: 'phone', value: 'Teléfono'},
    {key: 'rfc', value: 'RFC'},
    {key: 'curp', value: 'CURP'},
    {key: 'email', value: 'Correo Electrónico'},
    // {key: 'martial_status', value: 'Estado Civil'},
    // {key: 'minior_dependents', value: 'Dependientes menores de Edad'},
    // {key: 'senior_dependents', value: 'Dependientes Mayores'},
    {key: 'identification', value: 'Identificacion'},
    {key: 'imss', value: 'IMSS'},
    // {key: 'fiel', value: 'Fiel'},
    // {key: 'fiscal_regime', value: 'Régimen Fiscal'},
  ];

  keysCompanies = [
    {value: 'Nombre de la Empresa', key: 'business_name'},
    {value: 'Fecha de Inicio', key: 'start_date'},
    {value: 'Límite de Crédito', key: 'credit_limit'},
    {value: 'Saldo', key: 'balance'},
    {value: 'Crédito Disponible', key: 'credit_available'},
    {value: 'Tasa de Compañía', key: 'company_rate'},
    // {value: 'Adjunto', key: 'document'},
    {value: 'Sector', key: 'sector'},
    {value: 'Subsector', key: 'subsector'},
  ];

  keysContributorAddresses = [
    {value: 'Calle', key: 'street'},
    {value: 'Número Exterior', key: 'external_number'},
    {value: 'Número Interior', key: 'apartment_number'},
    {value: 'Colonia', key: 'suburb'},
    {value: 'Código Postal', key: 'postal_code'},
    {value: 'Referencias', key: 'address_reference'},
    {value: 'País', key: 'country'},
    {value: 'Estado', key: 'state_id'},
    {value: 'Municipio', key: 'municipality_id'},
    {value: 'Tipo de Dirección', key: 'address_type'},
    {value: 'Tipo de Residencia', key: 'suburb_type'},
  ];

  keysContributors = [
    {value: 'Número de Cuenta', key: 'account_number'},
    {value: 'Banco', key: 'bank'},
    {value: 'Clabe', key: 'clabe'},
    // {value: 'Persona', key: 'contributor_type'},
  ];

  formularioLegalEntities = new FormGroup({
    business_email: new FormControl( null , Validators.required ),
    business_name: new FormControl( null , Validators.required ),
    // created_at: new FormControl( null , Validators.required ),
    email: new FormControl( null , Validators.required ),
    // extra1: new FormControl( null , Validators.required ),
    // extra2: new FormControl( null , Validators.required ),
    // extra3: new FormControl( null , Validators.required ),
    fiel: new FormControl( null , Validators.required ),
    fiscal_regime: new FormControl( null , Validators.required ),
    // id: new FormControl( null , Validators.required ),
    main_activity: new FormControl( null , Validators.required ),
    mobile: new FormControl( null , Validators.required ),
    phone: new FormControl( null , Validators.required ),
    rfc: new FormControl( null , Validators.required ),
    rug: new FormControl( null , Validators.required ),
    // updated_at: new FormControl( null , Validators.required ),
  });

  formularioPeople: FormGroup = this.fb.group({
    birth_country : [''],
    birthdate : [''],
    birthplace : [''],
    created_at : [''],
    curp : [''],
    email : [''],
    extra1 : [''],
    extra2 : [''],
    extra3 : [''],
    fiel : [''],
    first_name : [''],
    fiscal_regime : [''],
    gender : [''],
    housing_type : [''],
    id : [''],
    id_type : [''],
    identification : [''],
    imss : [''],
    last_name : [''],
    martial_regime : [''],
    martial_status : [''],
    minior_dependents : [''],
    mobile : [''],
    nationality : [''],
    phone : [''],
    rfc : [''],
    second_last_name : [''],
    senior_dependents : [''],
    updated_at : [''],
  });

  formularioCompanies: FormGroup = this.fb.group({
    balance: [''],
    business_name: [''],
    company_rate: [''],
    contributor_id: [''],
    created_at: [''],
    credit_available: [''],
    credit_limit: [''],
    document: [''],
    id: [''],
    sector: [''],
    start_date: [''],
    subsector: [''],
    updated_at: [''],
  });

  formularioContributorAdresses: FormGroup = this.fb.group({
    address_reference: [''],
    address_type: [''],
    apartment_number: [''],
    contributor_id: [''],
    created_at: [''],
    external_number: [''],
    id: [''],
    municipality_id: [''],
    postal_code: [''],
    state_id: [''],
    street: [''],
    suburb: [''],
    suburb_type: [''],
    updated_at: [''],
  });

  formularioContributors: FormGroup = this.fb.group({
    account_number: [''],
    bank: [''],
    clabe: [''],
    contributor_type: [''],
    created_at: [''],
    extra1: [''],
    extra2: [''],
    extra3: [''],
    id: [''],
    legal_entity_id: [''],
    person_id: [''],
    updated_at: [''],
  });

  form = {};

  tipo = '';

  legal_entity = {};
  people = {};
  companies = {};
  contributor_addresses = {};
  contributors = {};
  countries = [];
  states = [];
  municipalities = [];
  country = '';
  lists = [];
  suburbTypes = [];
  addressTypes = [];

  constructor(
    private route: ActivatedRoute,
    private contribuyentesService: ContribuyentesService,
    private awsService: AwsService,
    private listasService: ListasService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.forma = new FormGroup({
      Key: new FormControl( null , Validators.required )
    } );
    this.contributorId = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.paramMap.get('type');
    this.contribuyentesService.get_contributor_register(this.contributorId).subscribe(resp => {
      this.tipo = resp['data']['type'];
      console.log(this.tipo, resp);
      if(this.tipo == 'legal_entities'){
        this.form['legal_entity'] = resp['data'].attributes;
        this.legal_entity = resp['data'].attributes;
      }else if(this.tipo == 'people'){
        this.form['people'] = resp['data'].attributes;
        this.people = resp['data'].attributes;
      }
      this.companies = resp['data'].relations.companies[0].attributes;
      this.contributor_addresses = resp['data'].relations.contributor_addresses[0].attributes;
      this.contributors = resp['data'].relations.contributors[0].attributes;
      // this.crearFormGroup(this.form, resp['data'].type);
      this.awsService.getCountriesD().subscribe(resp => {
        this.countries = resp;
        this.country = (this.countries.find(c => c.sortname == 'MX')).id;
        this.getStates();
        this.getMunicipios();
      })
    }, err => {
      console.log(err);
    });

    this.listasService.getLists().subscribe(lists => {
      lists['data'].forEach(list => {
        this.lists.push(list.attributes);
      });
      this.suburbTypes = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_SUBURB_TYPE');
      this.addressTypes = this.lists.filter(p => p.domain === 'CONTRIBUTOR_ADDRESS_ADDRESS_TYPE');
    })

  }

  getStates(){
    this.awsService.getStatesId(this.country).subscribe(resp => {
      this.states = resp;
    })
  }

  getMunicipios(){
    this.awsService.getMunicipios(this.contributor_addresses['state_id']).subscribe(resp => {
      this.municipalities = resp;
    })
  }

  crearFormGroup(contributor, tipo){
    if(tipo == 'legal_entities'){
      Object.keys(contributor.legal_entity).forEach(key => {
        try{
          this.formularioLegalEntities.controls[key].setValue(contributor.legal_entity[key]);
        }catch(error){
          console.log('error', key, error);
        }
      });
    }else if(tipo == 'people'){
      Object.keys(contributor.people).forEach(key => {
        try{
          this.formularioPeople.controls[key].setValue(contributor.people[key]);
        }catch(error){
          console.log('error', key, error);
        }
      });
    }
    Object.keys(contributor.companies).forEach(key => {
      try{
        this.formularioCompanies.controls[key].setValue(contributor.companies[key]);
      }catch(error){
        console.log('error', key, error);
      }
    });
    Object.keys(contributor.contributor_addresses).forEach(key => {
      try{
        this.formularioContributorAdresses.controls[key].setValue(contributor.contributor_addresses[key]);
      }catch(error){
        console.log('error', key, error);
      }
    });
    Object.keys(contributor.contributors).forEach(key => {
      try{
        this.formularioContributors.controls[key].setValue(contributor.contributors[key]);
      }catch(error){
        console.log('error', key, error);
      }
    });
    /* Object.keys(contributor).forEach(key => {
      try {
        this.miFormulario.controls[key].setValue(contributor[key]);
        
      } catch (error) {
        // console.log('error', key, error);
      }
    }); */
  }

  actualizar(){
    this.contribuyentesService.actualizarEmpleadoCadena(this.type, this.contributorId, this.miFormulario.value)
      .subscribe(resp => {
        swal2.fire('Éxito', 'Guardado correctamente', 'success');
        this.router.navigate(['/panelcontrol/empleadosCadenas']);
      });
  }

  guardar(){
    let actContributor = {
      company: this.companies,
      contributor_address: this.contributor_addresses,
      contributor: this.contributors
    }
    if(this.tipo == 'legal_entities'){
      actContributor['legal_entity'] = this.legal_entity;
      actContributor['legal_entity_id'] = this.legal_entity['id'];
    }else if(this.tipo == 'people'){
      actContributor['person'] = this.people;
      actContributor['person_id'] = this.people['id'];
    }

    actContributor['company_id'] = this.companies['id'];
    actContributor['contributor_id'] = this.contributors['id'];
    actContributor['contributor_address_id'] = this.contributor_addresses['id'];

    console.log(actContributor);
    this.contribuyentesService.company_update(actContributor).subscribe(resp => {
      swal2.fire('Éxito', 'Guardado correctamente', 'success');
        this.router.navigate(['/panelcontrol/empleadosCadenas']);
    }, err => {
      console.log('error', err);
      swal2.fire('Algo salió mal', 'Contacte al administrador', 'info');
    })
  }

}
