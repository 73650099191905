import { Injectable } from '@angular/core';
import { Usuario, Usuario2, Usuario3, UserOptions } from '../../models/usuario.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Perfisica, PerMoral, ContribuyenteFisica, ContribuyenteMoral, DocumentoPropiedad } from '../../models/personas.model';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class RegistrovalidacionService {
  

  usuario: Usuario;
  token: string;
  usuario2: Usuario2;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
      this.usuario = JSON.parse( localStorage.getItem('usuario') );
    } else {
      this.token = '';
      this.usuario = null;
    }

  }

getContribuyentes() {

  const url = `${environment.URL_SERVICIOS}/contributors?token=${this.token}&secret_key=${environment.SECRET_KEY}`;

  return this.http.get(url).pipe(
    map( (resp: any) => {
      return this.crearArregloContribuyente(resp);
    } )
  );

}

crearArregloContribuyente( contribuObj: any) {

  const contribuyentes: any[] = [];
  const resul: any[] = [];
  if ( contribuObj === null ) { return []; }
  // tslint:disable-next-line: forin
  for (const prop in contribuObj.data) {
  resul.push( contribuObj.data[prop].attributes );
  }

  return resul;

}

getFunder(cid) {
  const url = `${environment.URL_SERVICIOS}/contributors/${cid}/funders?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return this.crearArregloContribuyente(resp);
    } )
  );
}

getCustomer(cid) {
  const url = `${environment.URL_SERVICIOS}/contributors/${cid}/customers?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get(url).pipe(
    map( (resp: any) => {
      return this.crearArregloContribuyente(resp);
    } )
  );
}

getusuario( idu ) {
  const url = `${environment.URL_SERVICIOS}/users/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
  return this.http.get( url ).pipe(
    map( (resp: any) => {return (resp);
    }));
}

getContribuyente(idc) {
  const url = `${environment.URL_SERVICIOS}/contributors/${idc}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
  return this.http.get( url ).pipe(
    map( (resp: any) => {return (resp);
    }));
}


}
