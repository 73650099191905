import { Component, OnInit } from '@angular/core';
import { ListasService } from 'src/app/services/service.index';
import Swal2 from 'sweetalert2';
@Component({
  selector: 'app-listas',
  templateUrl: './listas.component.html',
  styleUrls: ['./listas.component.css']
})
export class ListasComponent implements OnInit {
  cols: any[];
  listas: any[] = [];
  constructor(private listasService: ListasService) { }

  ngOnInit(): void {
    Swal2.showLoading();
    this.listasService.getListas().subscribe( resp => { this.listas = resp; },
      err => {
        console.clear();
        console.log("ocurrio un error");
        // console.log(err); 
    }
      );

    this.cols = [

 
      { field: 'domain', header: 'Dominio' },
      { field: 'key', header: 'Key' },
      { field: 'value', header: 'Valor' },
      { field: 'description', header: 'Descripcion' },
      { field: 'herramientas', header: 'Herramientas' }

  ];
  Swal2.close();
  }

  borraLista( id: string ) {

    Swal2.fire({
      title: 'Desea Eliminar la Lista',
      text: 'Seleccionada',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      allowOutsideClick: false
    }). then ( resp => {
      if ( resp.value) {

        this.listasService.borrarLista( id ).subscribe( () => {

          Swal2.fire({
            title: 'La Lista',
            text: 'fue eliminada con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.ngOnInit();
            }

          } );

        }, (err) => {
          console.log(err);
          console.clear();
          Swal2.fire({
            title: 'Ocurrio un error',
            text: err.error.errors[0],
            icon: 'error',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );

      }
    });

  } 

}
