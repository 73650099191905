import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualizaperiodopago',
  templateUrl: './actualizaperiodopago.component.html',
  styles: []
})
export class ActualizaPeriodoPagoComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private condicionesService: CreditosService,
    public router: Router
  ) { }


  ngOnInit() {

      this.ido = this.route.snapshot.paramMap.get('id');

      this.forma = new FormGroup({
        Nombre: new FormControl( null , Validators.required ),
        Descripcion: new FormControl( null , Validators.required )
      } );

      this.condicionesService.getPeriodoPago( this.ido ).subscribe( resp => { this.service = resp; },
        err => {
          console.clear();
          console.log("ocurrio un error");
          // console.log(err); 
      } );

  }


  actualizaPeriodoPago () {

    // Obtener el elemento por el id
    const Key: any = document.getElementById('Key');
    const Descripcion: any = document.getElementById('Descripcion');
    const Valor: any = document.getElementById('Valor');
    const Tpp: any = document.getElementById('Tpp');
    // Obtener el valor de la opción seleccionada

    const valorKey = Key.value;
    const valorDescripcion = Descripcion.value;
    const valorValor = Valor.value;
    const valorTpp = Tpp.value;

    const params = {
      key: valorKey,
      description: valorDescripcion,
      value: valorValor,
      pp_type: valorTpp,
      secret_key: '',
      token: ''
    }

    this.condicionesService.actualizaPeriodoPago( this.ido, params ).subscribe( () => {
    Swal.fire({
      title: 'Modificacion de periodo pago exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/periodospago']);
      }

    } );

  }, (err) => {             console.clear();
    // console.log(err);
                            Swal.fire(
                              'Error al modificar condicion',
                              'Error',
                              'error'
                           );
                        } );

  }


}
