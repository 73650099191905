import { Component, EventEmitter, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-ver-doctores',
  templateUrl: './ver-doctores.component.html',
  styleUrls: ['./doctores.component.css']
})
export class VerDoctoresComponent implements OnInit {

  currentPage = 1;
  itemsPerPage = 10;
  searchTerm = '';
  items = [];
  cols = [
    {field: 'name', header: 'Nombre'},
    {field: 'rfc', header: 'RFC'},
    {field: 'cedula', header: 'Cédula'},
    {field: 'speciality', header: 'Especialidad'},
    {field: 'seniority', header: 'Antigüedad'},
    {field: 'nombre_vendedor', header: 'Vendedor asignado'},
    // {field: 'average_fee', header: },
    // {field: 'fee', header: },
    // {field: 'monthly_income', header: },
    // {field: 'surgery_income', header: },
    // {field: 'own_consultory', header: },
    // {field: 'payment_rent', header: },
    // {field: 'con_type', header: },
    // {field: 'month_patients', header: },
  ];
  editarDoctor = false;
  agregarDoctor = false;
  filaSeleccionada: number;
  showEU = false;
  actualizaDoctorEmitter = new EventEmitter<Object>();

  constructor(private catalogosService: CatalogosService,
              private usuarioService: UsuarioService) { }

  ngOnInit(): void {
    swal2.showLoading();

    var observableDoctores = this.catalogosService.getDoctores();
    var observableUsuarios = this.usuarioService.getUsuarios();

    forkJoin([observableDoctores, observableUsuarios]).subscribe(([doctores, usuarios]) => {
      this.items = [];
      doctores.forEach(d => {
        if(d.user_vendor_id){
          d['nombre_vendedor'] = usuarios.find(u => u.id == d.user_vendor_id).name;
        }
        this.items.push(d);
      })
    })

    /* this.catalogosService.getDoctores().subscribe( resp => { 
      this.items = resp;
    } ); */

    this.editarDoctor = true;
    this.agregarDoctor = false;
    swal2.close();
  }

  desplegarEditarDoctor() {
    this.editarDoctor = !this.editarDoctor;
    this.agregarDoctor = false;
  }

  editarDoctorEmit(contid: any, nomcont: any) {
    this.showEU = true;
    this.editarDoctor = false;
    this.agregarDoctor = false;
    setTimeout(() => {
      this.actualizaDoctorEmitter.emit({contid: contid, nomcont: nomcont});
    }, 100);   
  }

  cierraregusr(event) {
    this.showEU = false;
    this.editarDoctor = true;
    this.agregarDoctor = false;
    this.ngOnInit();
  }

  agregar() {
    this.agregarDoctor = true;
  }

  get totalPages(): number {
    return Math.ceil(this.filteredData.length / this.itemsPerPage);
  }

  get pages(): number[] {
    return Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  seleccionarFila(index: number, currentPage: number, itemsPerPage: number) {
    this.filaSeleccionada = index + (currentPage - 1) * itemsPerPage;
  }

  resetIndex() {
    this.currentPage = 1;
    this.filaSeleccionada = -1;
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredData.slice(startIndex, startIndex + this.itemsPerPage);
  }

  get filteredData(): any[] {
    return this.items.filter(item =>
      item.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.rfc.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
      item.speciality.toLowerCase().includes(this.searchTerm.toLowerCase())
    );
  }
// 
}
