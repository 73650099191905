import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
import { SignatoryesService } from 'src/app/services/service.index';

@Component({
  selector: 'app-signatoryes',
  templateUrl: './signatoryes.component.html',
  styleUrls: ['./signatoryes.component.css']
})
export class SignatoryesComponent implements OnInit {

  constructor(
    public route: Router,
    public singn: SignatoryesService,
    public http: HttpClient) { }

token = localStorage.getItem('token');
credits: any[] = [];
cols: any[];
selectedFac: any[];
router: Router;
tieneacceso = false;
ngOnInit() {
// this.tieneacceso = this.setings.getPermiso('/roles');
swal2.showLoading();
this.singn.getCustomerCredits().subscribe( resp => { this.credits = resp; swal2.close(); console.log(this.credits) },
err => {
  console.clear();
  console.log("ocurrio un error");
  // console.log(err); 
} );

this.cols = [

{ field: 'id', header: 'ID' },
{ field: 'status', header: 'Status' },
{ field: 'herramientas', header: 'Herramientas' }

];

}


}
