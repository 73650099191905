import { Component, OnInit } from '@angular/core';
import { UsuarioService, PrivilegiosUsuariosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-privilegiosusuarios',
  templateUrl: './privilegiosusuarios.component.html',
  styleUrls: ['./privilegiosusuarios.component.css']
})
export class PrivilegiosusuariosComponent implements OnInit {

  constructor( public _usuarioservice: UsuarioService,
    public _privilegiosusuarios: PrivilegiosUsuariosService,
    public router: Router,
    public http: HttpClient) { }

token = localStorage.getItem('token');
usuarios: any[] = [];
cols: any[];
selectedFac: any[];

ngOnInit() {
swal2.showLoading();

this._usuarioservice.getUsuarios().subscribe(resp => {this.usuarios = resp; },
  err => {
    console.clear();
    console.log("ocurrio un error");
    // console.log(err); 
} );

this.cols = [

{ field: 'id', header: 'ID' },
{ field: 'name', header: 'Nombre' },
{ field: 'email', header: 'Correo' },
{ field: 'privilegios', header: 'Privilegios' }
];
swal2.close();
}


}
