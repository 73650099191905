import { Component, OnInit } from '@angular/core';
import { ListasService, RolesService, UsuarioService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { AwsService } from 'src/app/services/service.index';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';

@Component({
  selector: 'app-crear-producto',
  templateUrl: './crear-producto.component.html',
  styles: [
  ]
})
export class CrearProductoComponent implements OnInit {

  forma: FormGroup;
  formaRc: FormGroup;
  resp: any;
  listaEstatus = [];
  genero: any[];
  showModal: boolean;
  idu = '';
  roles: any[];
  empresa = false;
  cliente = false;
  companies = [];
  firmantes: any[];
  usuario = false;
  data;

  constructor( public _usuarioService: UsuarioService,
               public http: HttpClient,
               private route: ActivatedRoute,
               private _roles: RolesService,
               public router: Router,
               private listasService: ListasService,
               private awsService: AwsService,
               private catalogosService: CatalogosService ) { }

  ngOnInit() {
    swal2.showLoading();
    const id = this.route.snapshot.paramMap.get('id');
    this.idu = this.route.snapshot.paramMap.get('id');
    this.data = JSON.parse(localStorage.getItem('data'));
    let usuario = this.route.snapshot.paramMap.get('usuario');
    if(usuario == 'usuario'){
      this.usuario = true;
    }
    this.forma = new FormGroup({
      marca: new FormControl( null),
      descripcionLarga: new FormControl( null),
      imagen: new FormControl( null),
      precioIva: new FormControl( null),
      modelo: new FormControl( null),
      precio: new FormControl( null),
      claveProducto: new FormControl( null),
      numeroProducto: new FormControl( null),
      tipoProducto: new FormControl( null),
      descripcionCorta: new FormControl( null),
      url: new FormControl( null),
    });

    swal2.close();
  }

  

sonIguales( campo1: string, campo2: string ) {

  return ( group: FormGroup ) => {

    let pass1 = group.controls[campo1].value;
    let pass2 = group.controls[campo2].value;

    if ( pass1 === pass2 ) {
      return null;
    }

    return {
      sonIguales: true
    };

  };

}

show() {
    this.showModal = true; // Show-Hide Modal Check
  }
hide() {
    this.showModal = false;
  }

  guardarCambios() {
    swal2.showLoading();
    // Obtener el elemento por el id
    const id = this.route.snapshot.paramMap.get('id');

    if ( this.forma.invalid ) {
      return;
    }


    const product = {
      short_description: (document.getElementById('short_description') as HTMLInputElement).value,
      detailed_description: (document.getElementById('detailed_description') as HTMLInputElement).value,
      brand: (document.getElementById('brand') as HTMLInputElement).value,
      model: (document.getElementById('model') as HTMLInputElement).value,
      product_number: (document.getElementById('product_number') as HTMLInputElement).value,
      product_key: (document.getElementById('product_key') as HTMLInputElement).value,
      product_type: (document.getElementById('product_type') as HTMLInputElement).value,
      price: (document.getElementById('price') as HTMLInputElement).value,
      iva_price: (document.getElementById('iva_price') as HTMLInputElement).value,
      image: (document.getElementById('image') as HTMLInputElement).value,
      url: (document.getElementById('url') as HTMLInputElement).value,
    }

    const params = {
      product: product
    }
    this.catalogosService.crearProducto(product).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El producto',
            text: 'fue creado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.router.navigate(['/panelcontrol/productos']);
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );
  }

}



