import { Component, OnInit } from '@angular/core';
import { ListasService, RolesService, UsuarioService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { AwsService } from 'src/app/services/service.index';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';

@Component({
  selector: 'app-crear-carro',
  templateUrl: './crear-carro.component.html',
  styles: [
  ]
})
export class CrearCarroComponent implements OnInit {
  forma: FormGroup;
  formaRc: FormGroup;
  resp: any;
  listaEstatus = [];
  genero: any[];
  showModal: boolean;
  idu = '';
  roles: any[];
  empresa = false;
  cliente = false;
  companies = [];
  firmantes: any[];
  usuario = false;
  data;

  constructor( public _usuarioService: UsuarioService,
               public http: HttpClient,
               private route: ActivatedRoute,
               private _roles: RolesService,
               public router: Router,
               private listasService: ListasService,
               private awsService: AwsService,
               private catalogosService: CatalogosService ) { }

  ngOnInit() {
    swal2.showLoading();
    const id = this.route.snapshot.paramMap.get('id');
    this.idu = this.route.snapshot.paramMap.get('id');
    this.data = JSON.parse(localStorage.getItem('data'));
    let usuario = this.route.snapshot.paramMap.get('usuario');
    if(usuario == 'usuario'){
      this.usuario = true;
    }
    this.forma = new FormGroup({
      marca: new FormControl( null),
      capacidad: new FormControl( null),
      clase: new FormControl( null),
      tipo: new FormControl( null),
      cilindros: new FormControl( null),
      puertas: new FormControl( null),
      combustible: new FormControl( null),
      linea: new FormControl( null),
      modelo: new FormControl( null),
    });


    swal2.close();
  }

  

sonIguales( campo1: string, campo2: string ) {

  return ( group: FormGroup ) => {

    let pass1 = group.controls[campo1].value;
    let pass2 = group.controls[campo2].value;

    if ( pass1 === pass2 ) {
      return null;
    }

    return {
      sonIguales: true
    };

  };

}

show() {
    this.showModal = true; // Show-Hide Modal Check
  }
hide() {
    this.showModal = false;
  }

  guardarCambios() {
    swal2.showLoading();
    // Obtener el elemento por el id
    const id = this.route.snapshot.paramMap.get('id');

    if ( this.forma.invalid ) {
      return;
    }


    const carro = {
      car_key: (document.getElementById('car_key') as HTMLInputElement).value,
      brand: (document.getElementById('brand') as HTMLInputElement).value,
      model: (document.getElementById('model') as HTMLInputElement).value,
      line: (document.getElementById('line') as HTMLInputElement).value,
      car_type: (document.getElementById('car_type') as HTMLInputElement).value,
      car_class: (document.getElementById('car_class') as HTMLInputElement).value,
      capacity: (document.getElementById('capacity') as HTMLInputElement).value,
      cylinder_number: (document.getElementById('cylinder_number') as HTMLInputElement).value,
      door_number: (document.getElementById('door_number') as HTMLInputElement).value,
      fuel: (document.getElementById('fuel') as HTMLInputElement).value,
    }

    const params = {
      car: carro
    }


    this.catalogosService.crearCar(carro).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El automóvil',
            text: 'fue creado con éxito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this.router.navigate(['/panelcontrol/automoviles']);
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );
  }

}


