import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oportunidades',
    templateUrl: './oportunidades.component.html',
    styleUrls: ['./oportunidades.component.scss']
})

export class OportunidadesComponent implements OnInit {
  focus: any;
  focus1: any;
  cards: any = [{ nombre_empresa: 'Amazon', 
                  descripcion_empresa: 'Empresa de Ventas por internet y servicios de tecnologia',
                  a_fondear: '1,200,150',
                  moneda: 'MXN',
                  porcentaje: '69',
                  dias_restantes: '30',
                  tasa: '16',
                  plazo: '36',
                  garantia: 'SI',
                  inversion_minima: '500',
                  calificacion: 'A',
                  rendimiento: 'Mensual',
                  imagen: 'https://static2.abc.es/media/tecnologia/2020/08/31/amazon-k4I--1200x630@abc.jpg',
                  id: 1
                } , 
                { nombre_empresa: 'Google', 
                descripcion_empresa: 'Empresa de ofimatica',
                a_fondear: '1,000,000',
                moneda: 'MXN',
                porcentaje: '59',
                dias_restantes: '60',
                tasa: '18',
                plazo: '48',
                garantia: 'NO',
                inversion_minima: '900',
                calificacion: 'B',
                rendimiento: 'Mensual',
                imagen: 'https://www.trecebits.com/wp-content/uploads/2019/06/Google-Logo.jpg',
                id: 2
              },
                { nombre_empresa: 'Cyberpuerta', 
                descripcion_empresa: 'Empresa de venta de equipo de computo por internet',
                a_fondear: '2,000,000',
                moneda: 'MXN',
                porcentaje: '59',
                dias_restantes: '60',
                tasa: '18',
                plazo: '50',
                garantia: 'NO',
                inversion_minima: '5000',
                calificacion: 'A',
                rendimiento: 'Mensual',
                imagen: 'https://www.eluniversal.com.mx/descuentos/static/shop/18618/logo/Cupones_Cyberpuerta.png',
                id: 3
              },
                ];
  constructor() { }
  ngOnInit() {
  }

}
