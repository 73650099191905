import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators'
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class ListasService {

  token: string;
  idUsuario: string;
  path: string =  environment.URL_SERVICIOS.replace('/api/v1','');

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

  crearArregloList( contribuObj: any) {
    const rr: any[] = [];
    const resul: any[] = [];
    if ( contribuObj === null ) { return []; }
    Object.keys ( contribuObj ).forEach( key => {
      const rol: any = contribuObj[key];
      rr.push( rol );
    });
    // tslint:disable-next-line: forin
    for ( const prop in rr[0] ) {
      resul.push( rr[0][prop].attributes.value );
    }
    return resul;
  }

  crearArregloListas( listasObj: any) {

    const listas: any[] = [];
    const resul: any[] = [];

    if ( listasObj === null ) { return []; }
    Object.keys ( listasObj ).forEach( key => {
      const rol: any = listasObj[key];
      listas.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in listas[0]) {
  //  console.log( usuarios[0][prop].attributes );
    resul.push( listas[0][prop].attributes );
    }

   // console.log(resul);

    return resul;

}

crearArregloLista( listasObj: any) {

  const listas: any[] = [];
  const resul: any[] = [];
  if ( listasObj === null ) { return []; }
  // tslint:disable-next-line: forin

//  console.log( usuarios[0][prop].attributes );
  resul.push( listasObj.data.attributes );

  return resul;

}

  getLists() {
  //   const params = new HttpParams () 
	// .set ('token', this.token)
	// .set ('secret_key', environment.SECRET_KEY); 
    const url = `${environment.URL_SERVICIOS}/lists?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    return this.http.get(url)
  
  }

  getLists2() {
  //   const params = new HttpParams () 
	// .set ('token', this.token)
	// .set ('secret_key', environment.SECRET_KEY); 
    const url = `${this.path}/lists2`;
    return this.http.get(url)
  
  }
  
  
  getAnualityDates() {
    const params = new HttpParams () 
	.set ('token', this.token)
	.set ('secret_key', environment.SECRET_KEY); 
    const url = `${environment.URL_SERVICIOS}/lists/domain/ANUALITY_DATE`;
    return this.http.post(url,params).pipe(
      map( (resp: any) => {
        return this.crearArregloList(resp);
      } )
    );
  
  }

  getListas() {
    const url = `${environment.URL_SERVICIOS}/lists?`;
    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    };
    return this.http.get( url, {params} ).pipe(
    map( (resp: any) => {return this.crearArregloListas(resp);
    })
    );

    }

  getLista( id: string ) {

      const url = `${environment.URL_SERVICIOS}/lists/${id}?token=${this.token}&secret_key=${environment.SECRET_KEY}`;
    
      return this.http.get( url ).pipe(
      map( (resp: any) => {return this.crearArregloLista(resp);
      }));
    
      }

  crearLista( dominio: string, key: string, valor: string, descripcion: string ) {
    const params = {
      domain: dominio,
      key: key,
      value: valor,
      description: descripcion,
      token: this.token,
      secret_key: environment.SECRET_KEY
    }

      const url = `${environment.URL_SERVICIOS}/lists?`;
      return this.http.post( url, params ).pipe(
                map( (resp: any) => {
                  return resp;
                }));
    }

  borrarLista(id: string) {
      const url = `${environment.URL_SERVICIOS}/lists/${id}?`;
      const params = {
        token: this.token,
        secret_key: environment.SECRET_KEY
      }; 
      return this.http.delete( url, {params} ).pipe(
        map( (resp: any) => { return resp;
         } ));
    
    }

    actualizaLista(idl: string, valor: string, desc: string, domain: string, key: string) {
      const params = {
        domain: domain,
        key: key,
        value: valor,
        description: desc,
        token: this.token,
        secret_key: environment.SECRET_KEY
      }

      const url = `${environment.URL_SERVICIOS}/lists/${idl}?`;
    
      return this.http.patch( url, params ).pipe(
        map( (resp: any) => { return resp;
        } ));
      }  
}
