import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// import * as swal from 'sweetalert';
import { AwsService, RolesService, UsuarioService } from '../../../services/service.index';
import { Usuario, Usuario2 } from '../../../models/usuario.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';
import { ListasService } from '../../../services/listas/listas.service';


@Component({
  selector: 'app-crearusuario',
  templateUrl: './crearusuario.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class CrearUsuarioComponent implements OnInit {
  @Output() childEvent = new EventEmitter();
  forma: FormGroup;
  genero: any[];
  estatus = [];
  roles: any[];
  firmantes: any[];
  empresa = false;
  cliente = false;
  companies = [];
  vendedor = false;

  constructor(
    public _usuarioService: UsuarioService,
    public router: Router,
    private listasService: ListasService,
    private _roles: RolesService,
    private awsService: AwsService
  ) { }

  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      let pass1 = group.controls[campo1].value;
      let pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }

  validar_email( email )
  {
      const regex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return regex.test(email) ? true : false;
  }

  ngOnInit() {

      this.forma = new FormGroup({
        nombre: new FormControl( null , Validators.required ),
        correo: new FormControl( null , [Validators.required, Validators.email] ),
        password: new FormControl( null , Validators.required ),
        password2: new FormControl( null , Validators.required ),
        puesto: new FormControl( null ),
        genero: new FormControl( null ),
        estatus: new FormControl( null ),
        rol: new FormControl( null )

      }, { validators: this.sonIguales( 'password', 'password2' )  } );

      this._usuarioService.getUserGender().subscribe( resp => this.genero = resp );

      this._usuarioService.getUserStatus().subscribe( resp => {this.estatus = resp; } );
      /* this.listasService.getLists().subscribe(resp => {
        resp['data'].forEach(list => {
          if(list['attributes'].domain === 'USER_STATUS'){
            this.estatus.push(list['attributes']);
          }
        });
        console.log(this.estatus)
      }) */

      this._roles.getRoles().subscribe(r => {this.roles = r;})
      this._usuarioService.getUsuarios().subscribe(f => {this.firmantes = f.filter(r => r.company_id != null);
    })
      this.awsService.getCompanyes().subscribe(resp => {
        this.companies = resp;
      })

  }

  selRol(){
    var rol = (<HTMLInputElement>document.getElementById('rol')).value;
    var name = this.roles.find(r => r.id == rol).name;
    if(name == 'Empresa'){
      this.empresa = true;
    }else{
      this.empresa = false;
    }
    if(name == 'Clientes'){
      this.cliente = true;
    }else{
      this.cliente = false;
    }
    if(name == 'Vendedor'){
      this.vendedor = true;
    }else{
      this.vendedor = false;
    }
  }

  registrarUsuario() {
    swal2.showLoading();
// Obtener el elemento por el id
    const genero: any = document.getElementById('genero');
    const estatus: any = document.getElementById('estatus');
    const rol = (<HTMLInputElement>document.getElementById('rol')).value;
    let company = '';
    try{
      company = (<HTMLInputElement>document.getElementById('company')).value;
    }catch{}
    let empresa = '';
    try{
      empresa = (<HTMLInputElement>document.getElementById('empresa')).value;
    }catch{}
// Obtener el valor de la opción seleccionada
    const valorGenero = genero.options[genero.selectedIndex].value;
    const valorEstatus = estatus.options[estatus.selectedIndex].value;

// Obtener el texto que muestra la opción seleccionada
//    let valorSeleccionado2 = this.genero.options[this.genero.selectedIndex].text;

    if ( this.validar_email( this.forma.value.correo ) ) {
    // el correo es valido
    } else {
      Swal.fire(
        'Error al crear usuario',
        'El correo electronico no es valido',
        'error'
     );
      return null;
    }

    if ( this.forma.value.password !== this.forma.value.password2 ) {

    Swal.fire(
      'Error al crear usuario',
      'Las contraseñas no son iguales',
      'error'
   );
    return null;
    }

    if ( this.forma.invalid ) {
      return;
    }

    const usuario = new Usuario2(
      this.forma.value.nombre,
      this.forma.value.correo,
      this.forma.value.password,
      this.forma.value.puesto,
      valorGenero,
      valorEstatus,
      rol,
      company,
      empresa
    );
    var auth = {
      'email': usuario.email,
      'password': usuario.password,
      'company_id': usuario.company,
      'name': usuario.nombre,
      'job': usuario.puesto,
      'gender': usuario.genero,
      'status': usuario.estatus,
      'role_id': usuario.rol,
    }
    var params = {auth: auth};
    this._usuarioService.registrarUsuario( params ).subscribe( resp => {
      swal2.close();
      Swal.fire({
        title: 'Creacion de usuario exitosa',
        text: '',
        icon: 'success',
        showConfirmButton: true,
        showCancelButton: false,
        allowOutsideClick: false
      }). then ( res => {
        if ( res.value ) {
          this.cerraroregusu();
        }
      } );
    }, (err) => {           
      swal2.close();
      console.log(err);
      // console.clear();
      var error = '';
      Object.keys(err.error.error).forEach(e => {
        var v = err.error.error;
        error += e + ': ' + v[e][0] + '\n';
      })
      Swal.fire(
          'Error al crear usuario',
          error,
          'error'
        );
    } );

 //   this.router.navigate([ '/verusuarios' ]);

  //  console.log(usuario);
  }

  cerraroregusu() {
    this.childEvent.emit({message: 'cerrarcreausr'})
  }



}
