import { Component, OnInit } from '@angular/core';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  usuarios: any[] = [];
  cols: any[];
  nombre = '';
  showmodalds = false;
  productoSeleccionado: any;
  constructor( private usuariosservice: UsuarioService,
                private catalogosService: CatalogosService ) {
  }

  ngOnInit(): void {
    
    this.catalogosService.getProductos().subscribe( resp => { 
      console.log(resp);
      this.usuarios = resp;
      /* for (const prop in this.usuarios) {
        if (this.usuarios[prop].role_id !== null) {
          this.usuariosservice.getrol(this.usuarios[prop].role_id).subscribe(r => {
            this.usuarios[prop].nombrerol = r.data.attributes.name;
            })
        } else {
          this.usuarios[prop].nombrerol = 'Sin rol asignado';
        }
        
      } */
    } );
    this.cols = [

      { field: 'product_key', header: 'Clave producto'},
      { field: 'short_description', header: 'Descripción corta'},
      // { field: 'detailed_description', header: 'Descripción'},
      { field: 'brand', header: 'Marca'},
      { field: 'model', header: 'Modelo'},
      { field: 'product_number', header: 'No. producto'},
      { field: 'product_type', header: 'Tipo'},
      { field: 'price', header: 'Precio'},
      { field: 'iva_price', header: 'Precio con IVA'},
      // { field: 'image', header: 'Imagen'},
      // { field: 'url', header: 'Liga'},
  ];

  }

  borrarProducto(id){
    this.catalogosService.borrarProducto(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Producto eliminado correctamente', 'success');
      this.ngOnInit();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

  detalleProducto(rowData){
    this.productoSeleccionado = rowData;
    this.showmodalds = true;
  }

  hideds(){
    this.showmodalds = false;
  }

   
  

}


