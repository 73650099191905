import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-creatipoarchivo',
  templateUrl: './creatipoarchivo.component.html',
  styles: []
})
export class CreaTipoArchivoComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private documentosService: DocumentosService,
    public router: Router
  ) { }


  ngOnInit() {
    this.forma = new FormGroup({
      Nombre: new FormControl( null , Validators.required ),
      Descripcion: new FormControl( null , Validators.required )

    } );
  }


  CreaService() {

    // Obtener el elemento por el id
    const Nombre: any = document.getElementById('Nombre');
    const Descripcion: any = document.getElementById('Descripcion');
    const Tcliente: any = document.getElementById('Tcliente');
    const Tfondeador: any = document.getElementById('Tfondeador');
    // Obtener el valor de la opción seleccionada

    const valorNombre = Nombre.value;
    const valorDescripcion = Descripcion.value;
    const valorTcliente = Tcliente.value;
    const valorTfondeador = Tfondeador.value;

    const params = {
      name: valorNombre,
      description: valorDescripcion,
      customer_type: valorTcliente,
      funder_type: valorTfondeador,
      secret_key: '',
      token: ''
    }

    this.documentosService.crearTipoArchivo( params ).subscribe( () => {
    Swal.fire({
      title: 'Creacion de tipo archivo exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/tiposarchivos']);
      }

    } );

  }, (err) => {            // console.clear();
     console.log(err);
                            Swal.fire(
                              'Ocurrio un error',
                              'Error',
                              'error'
                           );
                        } );

  }


}
