import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { ControlpanelRoutingModule } from './controlpanel-routing.module';
import { PanelcontrolComponent } from './panelcontrol/panelcontrol.component';
import { UsuariosComponent } from './catalogos/usuarios/usuarios.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ControlPanelComponent } from './controlpanel.component';
import {PANEL_ROUTES} from './controlpanel-routing.module';
import {SidebarModule} from 'primeng/sidebar';
import {TableModule} from 'primeng/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatMenuModule} from '@angular/material/menu';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import { EditarUsuarioComponent } from './catalogos/usuarios/editarusuario.component';
import { ListasComponent } from './configuracion/listas/listas.component';
import { CreaListaComponent } from './configuracion/listas/crealista.component';
import { ActualizaListaComponent } from './configuracion/listas/actualizalista.component';
import { OpcionesComponent } from './catalogos/opciones/opciones.component';
import { CreaOpcionComponent } from './catalogos/opciones/creaopcion.component';
import { ActualizaOpcionComponent } from './catalogos/opciones/actualizaopcion.component';
import { RolesComponent } from './catalogos/roles/roles.component';
import { CrearRolComponent } from './catalogos/roles/crearrol.component';
import { ActualizaRolComponent } from './catalogos/roles/actualizarol.component';
import { ParametrosgeneralesComponent } from './configuracion/parametrosgenerales/parametrosgenerales.component';
import { CreaParametroComponent } from './configuracion/parametrosgenerales/creaparametro.component';
import { ActualizaParametroComponent } from './configuracion/parametrosgenerales/actualizaparametro.component';
import { OpcionesusuariosComponent } from './catalogos/opcionesusuarios/opcionesusuarios.component';
import { AsignaOpcionesComponent } from './catalogos/opcionesusuarios/asignaopciones.component';
import { RolesopcionesComponent } from './catalogos/rolesopciones/rolesopciones.component';
import { AsignaOptionsRolesComponent } from './catalogos/rolesopciones/asignaopcionesrol.component';
import { PrivilegiosusuariosComponent } from './configuracion/privilegiosusuarios/privilegiosusuarios.component';
import { PrivilegioUsuarioComponent } from './configuracion/privilegiosusuarios/privilegiousuario.component';
import { CrearPrivilegioComponent } from './configuracion/privilegiosusuarios/crearprivilegio.component';
import { ActualizaPrivilegioComponent } from './configuracion/privilegiosusuarios/actualizaprivilegio.component';
import { CrearUsuarioComponent } from './catalogos/usuarios/crearusuario.component';
import { ServiciosexternosComponent } from './documentos/serviciosexternos/serviciosexternos.component';
import { ActualizaServicioExternoComponent } from './documentos/serviciosexternos/actualizaservicioexterno.component';
import { CreaServicioExternoComponent } from './documentos/serviciosexternos/creaservicioexterno.component';
import { TiposarchivosComponent } from './documentos/tiposarchivos/tiposarchivos.component';
import { ActualizaTipooArchivoComponent } from './documentos/tiposarchivos/actualizatipoarchivo.component';
import { CreaTipoArchivoComponent } from './documentos/tiposarchivos/creatipoarchivo.component';
import { DocumentoComponent } from './documentos/documento/documento.component';
import { CreaDocumentoComponent } from './documentos/documento/creadocumento.component';
import { ActualizaDocumentoComponent } from './documentos/documento/actualizadocumento.component';
import { TipoarchivodocumentoComponent } from './documentos/tipoarchivodocumento/tipoarchivodocumento.component';
import { CondicionesComponent } from './creditos/condiciones/condiciones.component';
import { CreaCondicionComponent } from './creditos/condiciones/creacondicion.component';
import { ActualizaCondicionComponent } from './creditos/condiciones/actualizacondicion.component';
import { PeriodospagoComponent } from './creditos/periodospago/periodospago.component';
import { CreaPeriodoPagoComponent } from './creditos/periodospago/creaperiodopago.component';
import { ActualizaPeriodoPagoComponent } from './creditos/periodospago/actualizaperiodopago.component';
import { CalificacioncreditosComponent } from './creditos/calificacioncreditos/calificacioncreditos.component';
import { CreacalificacioncreditosComponent } from './creditos/calificacioncreditos/creacalificacioncreditos.component';
import { ActualizaCalificacionCreditoComponent } from './creditos/calificacioncreditos/actualizacalificacioncredito.component';
import { TarifasComponent } from './creditos/tarifas/tarifas.component';
import { CreatarifaComponent } from './creditos/tarifas/creatarifa.component';
import { ActualizatarifaComponent } from './creditos/tarifas/actualizatarifa.component';
import { AprobarCreditosComponent } from './aprobar-creditos/aprobar-creditos.component'
import { RouterModule } from '@angular/router';
import { AprobarCreditoComponent } from './aprobar-creditos/aprobar-credito.component'
import { MesaControlComponent } from './aprobar-creditos/mesa-control/mesa-control.component';
import { DispersionComponent } from './dispersion/dispersion.component';
import { ReporteConsultasBuroComponent } from './reporte-consultas-buro/reporteconsultasburo.component';
import { CreditAnalisisComponent } from './creditanalisis/creditanalisis.component';
import {MultiSelectModule} from 'primeng/multiselect';
import {MatTabsModule} from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { AltaEmpleadosComponent } from './alta-empleados/alta-empleados.component';
import { RevisionDocumentosComponent } from './catalogos/revision-documentos/revision-documentos.component';
import { DoctoresComponent } from './catalogos/doctores/doctores.component';
import { EditarDoctorComponent } from './catalogos/doctores/editar-doctor.component';
import { CrearDoctorComponent } from './catalogos/doctores/crear-doctor.component';
import { ProductosComponent } from './catalogos/productos/productos.component';
import { EditarProductoComponent } from './catalogos/productos/editar-producto.component';
import { CrearProductoComponent } from './catalogos/productos/crear-producto.component';
import { CarrosComponent } from './catalogos/carros/carros.component';
import { EditarCarroComponent } from './catalogos/carros/editar-carro.component';
import { CrearCarroComponent } from './catalogos/carros/crear-carro.component';
import { CotizadorComponent } from './creditos/cotizador/cotizador.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { RevisarCotizacionesComponent } from './aprobar-creditos/revisar-cotizaciones/revisar-cotizaciones.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EmpresasComponent } from './catalogos/empresas/empresas.component';
import { CrearEmpresaComponent } from './catalogos/empresas/crear-empresa.component';
import { VerusuariosComponent } from './catalogos/usuarios/verusuarios.component';
import { VerDoctoresComponent } from './catalogos/doctores/ver-doctores.component';
import { VerEmpresasComponent } from './catalogos/empresas/ver-empresas.component';
import { ReporteCotizacionesComponent } from './catalogos/reporte-cotizaciones/reporte-cotizaciones.component';
@NgModule({
  declarations: [PanelcontrolComponent, 
    UsuariosComponent, 
    SidebarComponent, 
    ControlPanelComponent, 
    EditarUsuarioComponent, 
    ListasComponent,
    CreaListaComponent,
    ActualizaListaComponent,
    OpcionesComponent,
    CreaOpcionComponent,
    ActualizaOpcionComponent,
    RolesComponent,
    CrearRolComponent,
    ActualizaRolComponent,
    ParametrosgeneralesComponent,
    CreaParametroComponent,
    ActualizaParametroComponent,
    OpcionesusuariosComponent,
    AsignaOpcionesComponent,
    RolesopcionesComponent,
    AsignaOptionsRolesComponent,
    PrivilegiosusuariosComponent,
    PrivilegioUsuarioComponent,
    CrearPrivilegioComponent,
    ActualizaPrivilegioComponent,
    CrearUsuarioComponent,
    ServiciosexternosComponent,
    ActualizaServicioExternoComponent,
    CreaServicioExternoComponent,
    TiposarchivosComponent,
    ActualizaTipooArchivoComponent,
    CreaTipoArchivoComponent,
    DocumentoComponent,
    CreaDocumentoComponent,
    ActualizaDocumentoComponent,
    TipoarchivodocumentoComponent,
    CondicionesComponent,
    PeriodospagoComponent,
    CreaCondicionComponent,
    ActualizaCondicionComponent,
    CreaPeriodoPagoComponent,
    ActualizaPeriodoPagoComponent,
    CalificacioncreditosComponent,
    CreacalificacioncreditosComponent,
    ActualizaCalificacionCreditoComponent,
    TarifasComponent,
    CreatarifaComponent,
    ActualizatarifaComponent,
    AprobarCreditosComponent,
    AprobarCreditoComponent,
    MesaControlComponent,
    DispersionComponent,
    ReporteConsultasBuroComponent,
    CreditAnalisisComponent,
    AltaEmpleadosComponent,
    RevisionDocumentosComponent,
    DoctoresComponent,
    EditarDoctorComponent,
    CrearDoctorComponent,
    ProductosComponent,
    EditarProductoComponent,
    CrearProductoComponent,
    CarrosComponent,
    EditarCarroComponent,
    CrearCarroComponent,
    CotizadorComponent,
    RevisarCotizacionesComponent,
    EmpresasComponent,
    CrearEmpresaComponent,
    VerusuariosComponent,
    VerDoctoresComponent,
    VerEmpresasComponent,
    ReporteCotizacionesComponent
  ],
  imports: [
    CommonModule, 
    PANEL_ROUTES, 
    SidebarModule, 
    BrowserAnimationsModule, 
    BrowserModule, 
    ButtonModule, 
    PanelMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatCheckboxModule,
    FormsModule,
    MatMenuModule,
    AvatarModule,
    AvatarGroupModule,
    ScrollPanelModule,
    TableModule,
    ReactiveFormsModule,
    RouterModule,
    MultiSelectModule,
    MatTabsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressBarModule
  //  ControlpanelRoutingModule
  ],
  providers: [],
  bootstrap: []
})
export class ControlpanelModule { }
