import { Component, OnInit } from '@angular/core';
import { ReportesService } from 'src/app/services/reportes/reportes.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reporte-cotizaciones',
  templateUrl: './reporte-cotizaciones.component.html',
  styleUrls: ['./reporte-cotizaciones.component.css']
})
export class ReporteCotizacionesComponent implements OnInit {

  cols = [
    {field: 'quote_folio', header: 'Folio cotización'},
    {field: 'date', header: 'Fecha'},
    {field: 'total', header: 'Total'},
    {field: 'name', header: 'Nombre vendedor'},
    {field: 'email', header: 'Correo vendedor'},
  ];

  quotes = [];
  filteredQuotes = [];

  sortColumn: string = ''; // Columna actual de ordenación
  sortDirection: 'asc' | 'desc' = 'asc'; // Dirección de ordenación
  currentPage: number = 1;
  itemsPerPage: number = 10;
  searchTerm: string = '';
  maxVisiblePages: number = 8; // Número máximo de páginas visibles
  searchTerms = {
    quote_folio: '',
    date: '',
    total: '',
    name: '', 
    mail: '', 
  };

  constructor(private reportesService: ReportesService) { }

  ngOnInit(): void {
    this.reportesService.allQuotes().subscribe(resp => {
      this.quotes = JSON.parse(JSON.stringify(resp));
    })
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }
  
  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }
  
  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
    }
  }
  
  goToEllipsisPage(position: 'first' | 'last'): void {
    if (position === 'first') {
      this.currentPage = 1;
    } else if (position === 'last') {
      this.currentPage = this.totalPages;
    }
  }

  get totalPages(): number {
    return Math.ceil(this.filteredDataq.length / this.itemsPerPage);
  }

  get pages(): number[] {
    const totalPages = this.totalPages;
  
    if (totalPages <= this.maxVisiblePages) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
  
    const pages = [];
    const halfRange = Math.floor(this.maxVisiblePages / 2);
  
    let startPage = Math.max(1, this.currentPage - halfRange);
    let endPage = Math.min(totalPages, startPage + this.maxVisiblePages - 1);
  
    if (endPage === totalPages) {
      startPage = totalPages - this.maxVisiblePages + 1;
    }
    if (startPage === 1) {
      endPage = this.maxVisiblePages;
    }
  
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
  
    return pages;
  }

  pgone() {
    this.currentPage = 1;
  }

  sortData(column: string): void {
    // Cambia la dirección de ordenación si se selecciona la misma columna, de lo contrario reinicia a ascendente
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }
  }

  private compare(a: any, b: any, column: string, direction: 'asc' | 'desc'): number {
    if (!column) return 0;
  
    const valueA = a[column];
    const valueB = b[column];
  
    let comparison = 0;
    if (valueA > valueB) {
      comparison = 1;
    } else if (valueA < valueB) {
      comparison = -1;
    }
  
    return direction === 'asc' ? comparison : -comparison;
  }

  exportExcelFil() {
    let fullData = this.filteredDataq
    const readOpts = {
        cellText: false, 
        cellDates: true,
    };
   // Generar los datos personalizados para exportación
   const dataToExport = fullData.map(item => ({
    'Folio cotización': item.quote_folio || '',
    'Fecha': this.formatFecha(item.date) || '',
    'Total': this.removeCommas(item.total) || '',
    'Nombre vendedor': item.user_vendor.name || '',
    'Correo vendedor': item.user_vendor.email || '',
  }));
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport, readOpts);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'cotizaciones.xlsx', readOpts);
  }

  formatFecha(fecha: { split: (arg0: string) => [any, any, any]; }) {
    if (!fecha) return '';
    const [year, month, day] = fecha.split('-');
    return `${day}/${month}/${year}`;
  }
  
  removeCommas(monto: { toString: () => string; }) {
    if (!monto) return '';
    return monto.toString().replace(/,/g, ''); // Elimina todas las comas
  }

  get filteredDataq(): any[] {
    const filtered = this.quotes.filter(item => //return
      (!this.searchTerms.quote_folio || (item.quote_folio && item.quote_folio.toLowerCase().includes(this.searchTerms.quote_folio.toLowerCase()))) &&
      (!this.searchTerms.date || (item.date && item.date.toLowerCase().includes(this.searchTerms.date.toLowerCase()))) &&
      (!this.searchTerms.name || (item.user_vendor.name && item.user_vendor.name.toLowerCase().includes(this.searchTerms.name.toLowerCase()))) &&
      (!this.searchTerms.mail || (item.user_vendor.mail && item.user_vendor.mail.toLowerCase().includes(this.searchTerms.mail.toLowerCase()))) &&
      (!this.searchTerms.total || (item.total && item.total.toLowerCase().includes(this.searchTerms.total.toLowerCase())))
    );
    return filtered.sort((a, b) => this.compare(a, b, this.sortColumn, this.sortDirection));
  }

  get dataToShow(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    return this.filteredDataq.slice(startIndex, startIndex + this.itemsPerPage);
  }

}
