import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { RolesService, UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-modregemp',
  templateUrl: './modregemp.component.html',
  styleUrls: ['./modregemp.component.css']
})
export class ModregempComponent implements OnInit {

  usuarios: any[] = [];
  usuariosclien: any[] = [];
  usuarioscliente: any[] = [];
  //cols: any[];
  nombre = '';
  num = 0;
  cols = [
    { field: 'name', header: 'Nombre' },
    { field: 'email', header: 'Correo' },
    { field: 'job', header: 'Puesto' },
    { field: 'gender', header: 'Genero' },
    { field: 'status', header: 'Estatus' },
    { field: 'nombreRol', header: 'Rol' },
    { field: 'herramientas', header: 'Herramientas' }
];
  constructor( private usuariosservice: UsuarioService,
                private rolesService: RolesService ) {
  }

  ngOnInit(): void {
    swal2.showLoading();
    var observable1 = this.usuariosservice.getUsuarios();
    var observable2 = this.rolesService.getRoles();
    forkJoin([observable1, observable2]).subscribe(([usuarios, roles]) => {
      usuarios.forEach(usuario => {
        if(usuario.role_id != null){
          var rol = roles.find(r => r.id == usuario.role_id).name;
          usuario['nombreRol'] = rol;
          this.usuariosclien.push(usuario);
        }
      });
      swal2.close();
    });
    
    
    /* this.usuariosservice.getUsuarios().subscribe( async resp => { this.usuarios = resp;
      console.log(this.usuarios)
    for await (const prop of this.usuarios) {
      if (prop.role_id !== null) {
      await this.usuariosservice.getrol(prop.role_id).toPromise().then(r => {
        prop.nombreRol = r.data.attributes.name;
          if (r.data.attributes.name === 'Clientes') {
            this.usuarioscliente.push(prop);
          }
      })
      } 
    }
    this.usuariosclien = this.usuarioscliente
    swal2.close()
    } ); */
  }
}
