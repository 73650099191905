import { NgModule } from '@angular/core';
import { PAGES_ROUTES } from './pages-routing.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser'
// NG MODULES
import {CalendarModule} from 'primeng/calendar';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {SliderModule} from 'primeng/slider';
import {ChartModule} from 'primeng/chart';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {RadioButtonModule} from 'primeng/radiobutton';

import {MatStepperModule, } from '@angular/material/stepper';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input'
import {MatRadioModule} from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';

import { LapruebaComponent } from './laprueba/laprueba.component'
import { MaininversionistaComponent } from './maininversionista/maininversionista.component';
import { OportunidadComponent } from './oportunidades/oportunidad.component';
import { RegistrousuarioComponent } from './registro/registrousuario/registrousuario.component';
import { RegistrocontribuyenteComponent } from './registro/registrocontribuyente/registrocontribuyente.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistrodocumentosComponent } from './registro/registrodocumentos/registrodocumentos/registrodocumentos.component';
import { MainclienteComponent } from './maincliente/maincliente.component';
import { SolicitudcreditoComponent } from './maincliente/solicitudcredito.component';
import { WebcamModule } from 'ngx-webcam';
import { EmpleadosCadenasComponent } from './empleados-cadenas/empleados-cadenas.component';
import { ActualizarEmpleadosCadenasComponent } from './empleados-cadenas/actualizar-empleados-cadenas/actualizar-empleados-cadenas.component';
import { RouterModule } from '@angular/router';
import { AltaCadenasComponent } from './empleados-cadenas/alta-cadenas/alta-cadenas.component';
import { RegistroempleadoComponent } from './registroempleado/registroempleado.component';
import { SignatoryesComponent } from './signatoryes/signatoryes.component';
import { SignatoryesDetComponent } from './signatoryes/signatoryesdet.component';
import { ModregempComponent } from './modregemp/modregemp.component';
import { ModregempdetComponent } from './modregemp/modregempdet.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component'

@NgModule({
  declarations: [ 
    LapruebaComponent,
    MaininversionistaComponent,
    OportunidadComponent,
    RegistrousuarioComponent,
    RegistrocontribuyenteComponent,
    RegistroComponent,
    RegistrodocumentosComponent,
    MainclienteComponent,
    SolicitudcreditoComponent,
    EmpleadosCadenasComponent,
    ActualizarEmpleadosCadenasComponent,
    AltaCadenasComponent,
    RegistroempleadoComponent,
    SignatoryesComponent,
    SignatoryesDetComponent,
    ModregempComponent,
    ModregempdetComponent,
    AccountSettingsComponent
  ],
  imports: [
    PAGES_ROUTES,
    CalendarModule,
    ChartModule,
    TableModule,
    TabViewModule,
    SliderModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AvatarModule,
    AvatarGroupModule,
    RadioButtonModule,
    WebcamModule,
    RouterModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: []
})
export class PagesModule { }