import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ListasService, RolesService, UsuarioService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal2 from 'sweetalert2';
import { AwsService } from 'src/app/services/service.index';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';

@Component({
  selector: 'app-editar-doctor',
  templateUrl: './editar-doctor.component.html',
  styles: [
  ]
})
export class EditarDoctorComponent implements OnInit {
  @Input() eventEmitter: EventEmitter<Object>;
  forma: FormGroup;
  formaRc: FormGroup;
  resp: any;
  listaEstatus = [];
  genero: any[];
  showModal: boolean;
  idu = '';
  roles: any[];
  empresa = false;
  cliente = false;
  companies = [];
  firmantes: any[];
  data;
  monedas = [];
  vendedores = [];
  id_usuario: any;

  constructor( public _usuarioService: UsuarioService,
               public http: HttpClient,
               private route: ActivatedRoute,
               private _roles: RolesService,
               public router: Router,
               private listasService: ListasService,
               private awsService: AwsService,
               private catalogosService: CatalogosService ) { }

  ngOnInit() {
    swal2.showLoading();
    this.idu = this.route.snapshot.paramMap.get('id');
    this.data = JSON.parse(localStorage.getItem('data'));
    this.forma = new FormGroup({
      tarifaPromedio: new FormControl( null),
      cedula: new FormControl( null),
      tarifa: new FormControl( null),
      ingresoMensual: new FormControl( null),
      nombre: new FormControl( null),
      consultorioPropio: new FormControl( null),
      pagoRenta: new FormControl( null),
      rfc: new FormControl( null),
      antiguedad: new FormControl( null),
      especialidad: new FormControl( null),
      ingresosCirugia: new FormControl( null),
    });


    this.subscribeToParentEmitter();
    swal2.close();
  }

  subscribeToParentEmitter(): void { 
    this.eventEmitter.subscribe((d: { contid: string; }) => {
      this.idu = d.contid;
      this.catalogosService.getDoctor( this.idu ).subscribe( resp => {
        this.resp = resp; 
        console.log(resp);
        this.id_usuario = resp[0]['user_id'];
        this._roles.getRoles().subscribe(roles => {
          var rolVendedor = roles.find(r => r.name == 'Vendedor').id;
          this._usuarioService.getUsuarios().subscribe(usuarios => {
            this.vendedores =  usuarios.filter(u => u.role_id == rolVendedor);
          })
        })
      }, err => {
          console.clear();
          console.log("ocurrio un error");
          // console.log(err); 
      }
        );
    })
  }

sonIguales( campo1: string, campo2: string ) {

  return ( group: FormGroup ) => {

    let pass1 = group.controls[campo1].value;
    let pass2 = group.controls[campo2].value;

    if ( pass1 === pass2 ) {
      return null;
    }

    return {
      sonIguales: true
    };

  };

}

show() {
    this.showModal = true; // Show-Hide Modal Check
  }
hide() {
    this.showModal = false;
  }

  setCurrency(numero, opcion){
    var cantidad = this.formatCurrency(numero)
    this.resp[0][opcion] = cantidad;
    if(!this.monedas.includes(opcion)){
      this.monedas.push(opcion);
    }
  }

  formatCurrency(numero){
    var respuesta = numero.replace(/\D/g, "")
    .replace(/([0-9])([0-9]{2})$/, '$1.$2')
    .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ",");
    return respuesta;
  }

  guardarCambios(item) {
    swal2.showLoading();

    if ( this.forma.invalid ) {
      return;
    }

    this.monedas.forEach(m => {
      item[m] = item[m].replaceAll(',', '');
    })

    const params = {
      doctor: item
    }
    console.log(params);

    this.catalogosService.actualizaDoctor(this.idu, params).subscribe( () => {
          swal2.close();
          swal2.fire({
            title: 'El usuario',
            text: 'fue Modificado con exito',
            icon: 'success',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {

            if ( res.value ) {
              this._usuarioService.actualizaGeneralUsuario(this.id_usuario, {auth: {user_vendor_id: item.user_vendor_id}}).subscribe(() => {
                window.location.reload();
              })
            }

          } );

        }, (err) => {
          swal2.close();
          console.log(err);
          swal2.fire({
            title: 'Ocurrio un error',
            text: '',
            icon: 'info',
            showConfirmButton: true,
            showCancelButton: false,
            allowOutsideClick: false
          }). then ( res => {
            if ( res.value ) {
              this.ngOnInit();
            }
          } );
         } );
  }

}

