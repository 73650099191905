import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


@Injectable()
export class RolesService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }


  crearRol( params ) {
    params.token = this.token;
    params.secret_key = environment.SECRET_KEY;
    const url = `${ environment.URL_SERVICIOS }/roles?`;
    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getRoles() {

    const url = `${ environment.URL_SERVICIOS }/roles?token=${ this.token }&secret_key=${ environment.SECRET_KEY }`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloRoles(resp);
    }));

    }

  crearArregloRoles( rolesObj: any) {

    const roles: any[] = [];
    const resul: any[] = [];

    if ( rolesObj === null ) { return []; }
    Object.keys ( rolesObj ).forEach( key => {
      const rol: any = rolesObj[key];
      roles.push( rol );
    });
    // tslint:disable-next-line: forin
    for (const prop in roles[0]) {
    resul.push( roles[0][prop].attributes );
    }
    return resul;

}

getRol( id: string ) {

  const url = `${ environment.URL_SERVICIOS }/roles/${ id }?token=${ this.token }&secret_key=${ environment.SECRET_KEY }`;

  return this.http.get( url ).pipe(
    map ( (resp: any) => { return this.crearArregloRol(resp);
    } ));

}

async isAdmin( id: string ) {

  const url = `${ environment.URL_SERVICIOS }/roles/${ id }?token=${ this.token }&secret_key=${ environment.SECRET_KEY }`;
  
  this.http.get( url ).
  subscribe(resp => {
    let res;
    if(resp['data'].attributes.name === 'SuperAdmin'){
      res = true;
    }else{
      res = false;
    }
    return res;
  })
    // map ( (resp: any) => { 
    //   if(resp['data'].attributes.name === 'SuperAdmin'){
    //     return true;
    //   }else{
    //     return false;
    //   }
    // } );

}

crearArregloRol( rolObj: any) {

  const rol: any[] = [];
  const resul: any[] = [];
  if ( rolObj === null ) { return []; }
  Object.keys ( rolObj ).forEach( key => {
    const r: any = rolObj[key];
    rol.push( r );
  });
  resul.push( rolObj.data.attributes );
  return resul;

}

actualizaRol(idr: string, params) {
params.token = this.token,
params.secret_key = environment.SECRET_KEY
const url = `${ environment.URL_SERVICIOS }/roles/${idr}?`;

return this.http.patch( url, params ).pipe(
  map( (resp: any) => { return resp;
  } ));
}

borrarRol(id: string) {

  const url = `${ environment.URL_SERVICIOS }/roles/${ id }?token=${ this.token }&secret_key=${ environment.SECRET_KEY }`;

  return this.http.delete( url ).pipe(
    map( (resp: any) => { return resp;
     } ));

}

prueba() {
  const params = new HttpParams () 
	.set ('token', this.token)
	.set ('secret_key', environment.SECRET_KEY); 
  const url = `${environment.URL_SERVICIOS}/lists/domain/usuario.estatus`;

  return this.http.post( url,params ).pipe(
    map ( (resp: any) => { return resp;
    } ));
}

}
