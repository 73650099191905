import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-actualizatarifa',
  templateUrl: './actualizatarifa.component.html',
  styleUrls: []
})
export class ActualizatarifaComponent implements OnInit {
  forma: FormGroup;
  ido: string;
  service: any[] = [];
  condiciones: any[] = [];
  periodospagos: any[] = [];
  calificacioncreditos: any[] = [];
  constructor(private route: ActivatedRoute,
    private creditosService: CreditosService,
    public router: Router) { }

  ngOnInit(): void {
    this.ido = this.route.snapshot.paramMap.get('id');
    this.creditosService.getCondiciones().subscribe( resp => { this.condiciones = resp; console.log(resp)} );
    this.creditosService.getperiodosPagos().subscribe ( resp => this.periodospagos = resp )
    this.creditosService.getCalificacionCreditos().subscribe( resp => this.calificacioncreditos = resp )
      this.forma = new FormGroup({
        Nombre: new FormControl( null , Validators.required ),
        Descripcion: new FormControl( null , Validators.required )
      } );

      this.creditosService.getRate( this.ido ).subscribe( resp => { this.service = resp; },
        err => {
         // console.clear();
          console.log("ocurrio un error");
          // console.log(err); 
      } );
  }

  actualizaTarifa () {

    // Obtener el elemento por el id
    const Key: any = document.getElementById('Key');
    const Descripcion: any = document.getElementById('Descripcion');
    const Valor: any = document.getElementById('Valor');
    const Condicion: any = document.getElementById('Condicion');
    const Periodospago: any = document.getElementById('Periodospago');
    const Calificacioncredito: any = document.getElementById('Calificacioncredito');
    // Obtener el valor de la opción seleccionada

    const valorKey = Key.value;
    const valorDescripcion = Descripcion.value;
    const valorValor = Valor.value;
    const valorCondicion = Condicion.value
    const valorPeriodospago = Periodospago.value
    const valorCalificacioncredito = Calificacioncredito.value

    const params = {
      key: valorKey,
      description: valorDescripcion,
      value: valorValor,
      term_id: valorCondicion,
      payment_period_id: valorPeriodospago,
      credit_rating_id: valorCalificacioncredito,
      secret_key: '',
      token: ''
    }

    this.creditosService.actualizaRate( this.ido, params ).subscribe( () => {
    Swal.fire({
      title: 'Modificacion de tarifa exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/tarifas']);
      }

    } );

  }, (err) => {             console.clear();
    // console.log(err);
                            Swal.fire(
                              'Error al modificar tarifa',
                              'Error',
                              'error'
                           );
                        } );

  }

}
