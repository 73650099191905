import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  token: string;
  idUsuario: string;

  constructor(
    public http: HttpClient,
    public router: Router
  ) {
    this.cargarStorage();
  }

  cargarStorage() {

    if ( localStorage.getItem('tkn')) {
      this.token = localStorage.getItem('tkn');
    } else {
      this.token = '';
    }

  }

  crearArregloData( Obj: any) {
    const arr: any[] = [];
    const resul: any[] = [];
    if ( Obj === null ) { return []; }
    Object.keys ( Obj ).forEach( key => {
      const fo: any = Obj[key];
      arr.push( fo );
    });
    resul.push( arr[0].attributes );
    return resul;
}

  crearArregloUsuarios( usuariosObj: any) {

    const usuarios: any[] = [];
    const resul: any[] = [];

    if ( usuariosObj === null ) { return []; }
    Object.keys ( usuariosObj ).forEach( key => {
      const usuario: any = usuariosObj[key];
      usuarios.push( usuario );
    });
    // tslint:disable-next-line: forin
    for (const prop in usuarios[0]) {
    //  console.log( usuarios[0][prop].attributes );
      resul.push( usuarios[0][prop].attributes );
    }

  //  console.log(resul);

    return resul;

  }

  // --------------------------------------- DOCTORES -------------------------------------
  crearDoctor( params ) {
    params['token'] = this.token;
    params['secret_key'] = environment.SECRET_KEY;

    const url = `${environment.URL_SERVICIOS}/user_doctors`;


    return this.http.post( url, params ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getDoctor( idu ) {
    const url = `${environment.URL_SERVICIOS}/doctors/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return this.crearArregloData(resp);
      }));
  }

  getDoctores() {

    const url = `${environment.URL_SERVICIOS}/doctors?secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloUsuarios(resp);
    }));

  }

  getDoctoresPorVendedor(id) {

    const url = `${environment.URL_SERVICIOS}/reports/get_doctor_vendor?user_vendor_id=${id}&secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return resp;
    }));

  }

  actualizaDoctor(id, params) {
    const url = `${environment.URL_SERVICIOS}/doctors/${id}?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
    return this.http.patch( url, params ).pipe(
      map( (resp: any) => { return resp;
      } ));
    }

  borrarDoctor(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/doctors/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
        } ));
  
  }


  //  -------------------------------------------- FIN DOCTORES ---------------------------------------

  // ------------------------------------------- PRODUCTOS ---------------------------------------------

  crearProducto( params ) {

    const url = `${environment.URL_SERVICIOS}/products?product[brand]=${params.brand}&product[detailed_description]=${params.detailed_description}&product[image]=${params.image}&product[iva_price]=${params.iva_price}&product[model]=${params.model}&product[price]=${params.price}&product[product_key]=${params.product_key}&product[product_number]=${params.product_number}&product[product_type]=${params.product_type}&product[short_description]=${params.short_description}&product[url]=${params.url}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;


    return this.http.post( url, null ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getProducto( idu ) {
    const url = `${environment.URL_SERVICIOS}/products/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return this.crearArregloData(resp);
      }));
  }

  getProductos() {

    const url = `${environment.URL_SERVICIOS}/products?secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloUsuarios(resp);
    }));

  }

  actualizaProducto(id, params) {
    const url = `${environment.URL_SERVICIOS}/products/${id}?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
    return this.http.patch( url, params ).pipe(
      map( (resp: any) => { return resp;
      } ));
    }

  borrarProducto(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/products/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
        } ));
  
  }

  // -------------------------------- FIN PRODUCTOS --------------------------------

  // ------------------------------- CARROS ----------------------------------------

  crearCar( params ) {

    const url = `${environment.URL_SERVICIOS}/cars?car[brand]=${params.brand}&car[capacity]=${params.capacity}&car[car_class]=${params.car_class}&car[car_key]=${params.car_key}&car[car_type]=${params.car_type}&car[cylinder_number]=${params.cylinder_number}&car[door_number]=${params.door_number}&car[fuel]=${params.fuel}&car[line]=${params.line}&car[model]=${params.model}&token=${this.token}&secret_key=${environment.SECRET_KEY}`;


    return this.http.post( url, null ).pipe(
              map( (resp: any) => {
                return resp;
              }));
  }

  getCar( idu ) {
    const url = `${environment.URL_SERVICIOS}/cars/${idu}?token=${this.token}&secret_key=${environment.SECRET_KEY}`
    return this.http.get( url ).pipe(
      map( (resp: any) => {return this.crearArregloData(resp);
      }));
  }

  getCars() {

    const url = `${environment.URL_SERVICIOS}/cars?secret_key=${environment.SECRET_KEY}&token=${this.token}`;

    return this.http.get( url ).pipe(
    map( (resp: any) => {return this.crearArregloUsuarios(resp);
    }));

  }

  actualizaCar(id, params) {
    const url = `${environment.URL_SERVICIOS}/cars/${id}?secret_key=${environment.SECRET_KEY}&token=${this.token}`;
    return this.http.patch( url, params ).pipe(
      map( (resp: any) => { return resp;
      } ));
    }

  borrarCar(id: string) {

    const params = {
      token: this.token,
      secret_key: environment.SECRET_KEY
    }
  
    const url = `${ environment.URL_SERVICIOS }/cars/${ id }?`;
  
    return this.http.delete( url, {params} ).pipe(
      map( (resp: any) => { return resp;
        } ));
  
  }
}
