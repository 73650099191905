import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OpcionesService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import swal2 from 'sweetalert2';
import { HttpParams } from '@angular/common/http';

declare function init_plugins();

@Component({
  selector: 'app-creaopcion',
  templateUrl: './creaopcion.component.html',
  styles: []
})
export class CreaOpcionComponent implements OnInit {

  forma: FormGroup;

  constructor(
    private route: ActivatedRoute,
    public _optionsservice: OpcionesService,
    public router: Router
  ) { }


  ngOnInit() {


      this.forma = new FormGroup({
        name: new FormControl( null , Validators.required ),
        description: new FormControl( null , Validators.required ),
        group: new FormControl( null , Validators.required ),
        url: new FormControl( null , Validators.required )

      } );

  }


  registrarOpcion() {
    swal2.showLoading();
    const valorName = this.forma.value.name;
    const valorDescripcion = this.forma.value.description;
    const valorGrupo = this.forma.value.group;
    const valorUrl = this.forma.value.url;
    this._optionsservice.crearOption(valorName, valorDescripcion, valorGrupo, valorUrl).subscribe( () => {
    swal2.close();
    Swal.fire({
      title: 'Creacion de opcion exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/opciones']);
      }

    } );

  }, (err) => {             console.log(err);
                            swal2.close();
                            console.clear();
                            Swal.fire(
                              'Error al crear Opcion',
                              'Error',
                              'error'
                           );
                        } ); 

  }


}
