import { Component, OnInit } from '@angular/core';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';

@Component({
  selector: 'app-doctores',
  templateUrl: './doctores.component.html',
  styles: [
  ]
})
export class DoctoresComponent implements OnInit {
  usuarios: any[] = [];
  cols: any[];
  nombre = '';
  constructor( private usuariosservice: UsuarioService,
                private catalogosService: CatalogosService ) {
  }

  ngOnInit(): void {
    
    this.catalogosService.getDoctores().subscribe( resp => { 
      this.usuarios = resp;
      /* for (const prop in this.usuarios) {
        if (this.usuarios[prop].role_id !== null) {
          this.usuariosservice.getrol(this.usuarios[prop].role_id).subscribe(r => {
            this.usuarios[prop].nombrerol = r.data.attributes.name;
            })
        } else {
          this.usuarios[prop].nombrerol = 'Sin rol asignado';
        }
        
      } */
    } );
    this.cols = [

      { field: 'name', header: 'Nombre' },
      { field: 'speciality', header: 'Especialidad' },
      { field: 'cedula', header: 'Cédula' },
      { field: 'rfc', header: 'RFC' },
      { field: 'seniority', header: 'Antigüedad' },
      { field: 'own_consultory', header: 'Consultorio propio' },
      { field: 'payment_rent', header: 'Pago renta' },
      { field: 'fee', header: 'Tarifa' },
      { field: 'surgery_income', header: 'Ingresos por cirugía' },
      { field: 'average_fee', header: 'Tarifa promedio' },
      { field: 'monthly_income', header: 'Ingreso mensual' },
  ];

  }

  borrarDoctor(id){
    this.catalogosService.borrarDoctor(id).subscribe(r => {
      console.log(r);
      swal2.fire('Éxito', 'Doctor eliminado correctamente', 'success');
      this.ngOnInit();
    }, err => {
      console.log(err);
      try{
        swal2.fire('Atención', err.error.error, 'info')
      }catch{
        swal2.fire('Atención', 'Algo salió mal', 'info')
      }
    })
  }

   
  

}

