import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CreditosService } from '../../../services/service.index';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-creacalificacioncreditos',
  templateUrl: './creacalificacioncreditos.component.html',
  styleUrls: []
})
export class CreacalificacioncreditosComponent implements OnInit {

  forma: FormGroup;
  ido: string;
  service: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private creditosService: CreditosService,
    public router: Router
  ) { }


  ngOnInit() {
    this.forma = new FormGroup({
      Key: new FormControl( null , Validators.required ),
      Descripcion: new FormControl( null , Validators.required )

    } );
  }


  CreaCalificacion() {

    // Obtener el elemento por el id
    const Key: any = document.getElementById('Key');
    const Descripcion: any = document.getElementById('Descripcion');
    const Valor: any = document.getElementById('Valor');
    const Tc: any = document.getElementById('Tc');
    // Obtener el valor de la opción seleccionada

    const valorKey = Key.value;
    const valorDescripcion = Descripcion.value;
    const valorValor = Valor.value;
    const valorTc = Tc.value;

    const params = {
      key: valorKey,
      description: valorDescripcion,
      value: valorValor,
      cr_type: valorTc,
      secret_key: '',
      token: ''
    }

    this.creditosService.crearCalificacionCreditos( params ).subscribe( () => {
    Swal.fire({
      title: 'Creacion de calificacion pag exitosa',
      text: '',
      icon: 'success',
      showConfirmButton: true,
      showCancelButton: false,
      allowOutsideClick: false
    }). then ( res => {
      if ( res.value ) {
        this.router.navigate(['/panelcontrol/calificacioncreditos']);
      }

    } );

  }, (err) => {            // console.clear();
    // console.log(err);
                            Swal.fire(
                              'Ocurrio un error',
                              'Error',
                              'error'
                           );
                        } );

  }

}
