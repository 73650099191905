import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
@Injectable()
export class ListasPcGuard implements CanActivate {
  rid: string;
  roloptions: any[] = [];
  p = false;
  constructor(
    public router: Router
  ) {}
    canActivate() {
    this.roloptions = JSON.parse(localStorage.getItem('data'));
    if ( this.roloptions.includes(btoa('Listas')) ) {
      return true
    } else {
      this.router.navigate(['/home']);
      return false;
    }
    
    
  }

}
