import { Component, OnInit } from '@angular/core';
import { DocumentosService } from 'src/app/services/service.index';
import swal2 from 'sweetalert2';
@Component({
  selector: 'app-tiposarchivos',
  templateUrl: './tiposarchivos.component.html',
  styleUrls: ['./tiposarchivos.component.css']
})
export class TiposarchivosComponent implements OnInit {
  cols: any[];
  archivos: any[];
  constructor( private documentosService: DocumentosService ) { }

  ngOnInit(): void {
    swal2.showLoading();
    this.documentosService.getTiposArchivos().subscribe( resp => { this.archivos = resp; swal2.close(); } );


this.cols = [
{ field: 'name', header: 'Nombre' },
{ field: 'description', header: 'Descripcion' },
{ field: 'customer_type', header: 'Tipo de cliente' },
{ field: 'funder_type', header: 'Tipo de fondeador' },
{ field: 'herramientas', header: 'Herramientas' }

];
  }

  borraTipoArchivo( id: string ) {
    swal2.showLoading();
    swal2.fire({
    title: 'Desea Eliminar el tipo de archivo',
    text: 'Seleccionado',
    icon: 'question',
    showConfirmButton: true,
    showCancelButton: true,
    allowOutsideClick: false
    }). then ( resp => {
    if ( resp.value) {
    
    this.documentosService.borrarTipoArchivo( id ).subscribe( () => {
    swal2.close();
    swal2.fire({
     title: 'El tipo de archivo',
     text: 'fue eliminado con exito',
     icon: 'success',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
    
     if ( res.value ) {
       this.ngOnInit();
     }
    
    } );
    
    }, (err) => {
    swal2.close();
    console.log(err);
    console.clear();
    swal2.fire({
     title: 'Ocurrio un error',
     text: '',
     icon: 'error',
     showConfirmButton: true,
     showCancelButton: false,
     allowOutsideClick: false
    }). then ( res => {
     if ( res.value ) {
       this.ngOnInit();
     }
    } );
    } );
    
    }
    });
    
    }  

}
